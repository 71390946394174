import { useState, useEffect, useCallback } from 'react';

import debounce from 'lodash/debounce';
import Select from 'react-select';

import { useFilter } from '@features/table-filter';
import ErrorMessage from '@shared/components/ErrorMessage';
import { reactSelectStyles } from '@shared/components/Form';
import { IDataResponse, TFetchQueryError } from '@shared/types/fetch-data';
import { IZodValidationError } from '@shared/zod';

interface ISelectAsyncOption {
  value: string;
  label: string;
}

interface IProps<T, Res extends IDataResponse<T>> {
  name: string;
  data: Res;
  getOptionLabel: (option: T) => string;
  getOptionValue: (option: T) => string;
  selectedValue?: string;
  onChange?: (value: string) => void;
  isLoading: boolean;
  filter: ReturnType<typeof useFilter>;
  error: IZodValidationError | TFetchQueryError;
}

const DEBOUNCE_DELAY = 300;
const DEBOUNCE_OPTIONS = { leading: true };

export const SelectAsync = <T, Res extends IDataResponse<T>>({
  name,
  data,
  getOptionLabel,
  getOptionValue,
  selectedValue,
  onChange,
  filter,
  isLoading,
  error,
}: IProps<T, Res>) => {
  const [inputValue, setInputValue] = useState('');
  const [hasMore, setHasMore] = useState(true);
  const [options, setOptions] = useState<ISelectAsyncOption[]>([]);
  const [selectedOption, setSelectedOption] = useState<ISelectAsyncOption | null>(null);

  const { setPage, updateFilter } = filter;
  const setQuery = useCallback(
    (query: string) => updateFilter({ 'filter[name]': query }),
    [updateFilter],
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedAsyncOptions = useCallback(
    debounce(
      (input: string) => {
        if (setQuery) setQuery(input);
      },
      DEBOUNCE_DELAY,
      DEBOUNCE_OPTIONS,
    ),
    [setQuery],
  );

  useEffect(() => {
    if (data) {
      const newOptions = data.data.map(item => ({
        value: getOptionValue(item),
        label: getOptionLabel(item),
      }));

      setOptions(prevOptions => {
        if (data.meta.current_page === 1) {
          return newOptions;
        }
        const combinedOptions = [...prevOptions, ...newOptions];
        const uniqueOptions = combinedOptions.filter(
          (option, index, self) => index === self.findIndex(o => o.value === option.value),
        );
        return uniqueOptions;
      });

      !data.links.next ? setHasMore(false) : setHasMore(true);
    }
  }, [data, getOptionLabel, getOptionValue]);

  useEffect(() => {
    if (selectedValue && options.length > 0 && !selectedOption) {
      const selected = options.find(option => option.value === selectedValue) || null;
      setSelectedOption(selected);
    }
  }, [selectedValue, options, selectedOption]);

  useEffect(() => {
    return () => debouncedAsyncOptions.cancel();
  }, [debouncedAsyncOptions]);

  const handleScrollToBottom = useCallback(() => {
    if (hasMore) setPage(prevPage => (Number(prevPage) + 1).toString());
  }, [hasMore, setPage]);

  const handleChange = useCallback(
    (selected: ISelectAsyncOption | null) => {
      setSelectedOption(selected);
      onChange?.(selected?.value ?? '');
    },
    [onChange],
  );

  const handleInputChange = useCallback(
    (input: string) => {
      setInputValue(input);
      if (input.trim() === '') {
        setQuery('');
        return;
      }
      debouncedAsyncOptions(input);
    },
    [debouncedAsyncOptions, setQuery],
  );

  if (error) {
    return <ErrorMessage error={error} />;
  }

  return (
    <>
      <Select
        classNamePrefix='react-select'
        className='react-select-container'
        inputValue={inputValue}
        isLoading={isLoading}
        isClearable
        hideSelectedOptions={false}
        maxMenuHeight={200}
        menuPosition='fixed'
        styles={reactSelectStyles<ISelectAsyncOption, false>()}
        menuShouldBlockScroll={true}
        menuShouldScrollIntoView={false}
        onChange={handleChange}
        onInputChange={handleInputChange}
        options={options}
        value={selectedOption}
        closeMenuOnSelect
        onMenuScrollToBottom={handleScrollToBottom}
      />
      <input type='hidden' name={name} value={selectedOption?.value ?? ''} aria-hidden='true' />
    </>
  );
};
