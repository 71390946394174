import { Kbd } from './Kbd';
import './hotkeys-modal.css';

const isMac = /Mac|iPod|iPhone|iPad/.test(navigator.userAgent);
const modKey = isMac ? '⌘' : 'Alt';

export const HotkeysModal = () => (
  <div className='hotkeys-help'>
    <section>
      <h3>Navigation Shortcuts</h3>
      <p>
        Press <Kbd>{modKey} + K</Kbd> to enter <b>command mode</b>, then:
      </p>

      <div className='shortcuts-group'>
        <h4>1. Select Section:</h4>
        <ul>
          <li>
            <Kbd>O</Kbd> — Orders
          </li>
          <li>
            <Kbd>R</Kbd> — Requests
          </li>
          <li>
            <Kbd>W</Kbd> — Wallets
          </li>
        </ul>
      </div>

      <div className='shortcuts-group'>
        <h4>2. Select Type:</h4>
        <ul>
          <li>
            <Kbd>D</Kbd> — Deposits
          </li>
          <li>
            <Kbd>W</Kbd> — Withdrawals
          </li>
        </ul>
      </div>

      <div className='shortcuts-group'>
        <h4>Exit:</h4>
        <ul>
          <li>
            <Kbd>Esc</Kbd> — Exit command mode at any time
          </li>
        </ul>
      </div>
      <section>
        <h3>Examples:</h3>
        <ul className='examples'>
          <li>
            <Kbd>{modKey} + K O D</Kbd> - Navigate to Orders Deposits
          </li>
          <li>
            <Kbd>{modKey} + K W W</Kbd> - Navigate to Wallets Withdrawals
          </li>
        </ul>
      </section>
    </section>
    <section>
      <h3>Generic Shortcuts</h3>
      <p>
        Press <Kbd>Shift + R</Kbd> to refresh the table page keeping the current filters and
        sorting. Works for <b>Orders</b>, <b>Requests</b> and <b>Transactions</b> tables.
      </p>
    </section>
  </div>
);
