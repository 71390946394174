import { Fragment } from 'react';

import { format } from 'date-fns';
import pick from 'lodash/pick';

import ReactJsonView, { ReactJsonViewProps } from '@microlink/react-json-view';
import { Box, Card, Divider, CardContent, CardHeader, Typography } from '@mui/material';

import { Highlight } from './Highlight';
import { JsonSchema } from './types';
import { commonJsonViewProps } from './ViewConfig';

interface IProps extends Partial<ReactJsonViewProps> {
  data: JsonSchema | JsonSchema[];
  promotedKeys?: string[];
  headerFn?: (entry: JsonSchema) => React.ReactNode;
  withDefaultId?: boolean;
}

export const CardRenderer = ({
  data,
  promotedKeys = [],
  headerFn,
  withDefaultId = true,
}: IProps) => {
  // In some cases backend sends in JSON as string, in this case we need to parse it
  const attemptParseJson = (item: JsonSchema) => {
    if ('new_values' in item && item.new_values !== null) {
      const newValues = item.new_values as JsonSchema;
      if ('payload' in newValues && newValues.payload !== null) {
        try {
          const parsed = JSON.parse(newValues.payload as string);
          return {
            ...item,
            new_values: {
              ...newValues,
              payload: parsed,
            },
          };
        } catch (e) {
          console.error('Error parsing JSON', e);
          return item;
        }
      }
    }
    return item;
  };
  const renderCard = (item: JsonSchema) => {
    return (
      <Box sx={{ position: 'relative' }}>
        <Card>
          <CardHeader
            sx={{ pb: 0 }}
            title={
              <Box display='flex' alignContent='center' flexDirection='row' gap={2}>
                {withDefaultId && (
                  <Typography>
                    id: <Highlight>{item.id}</Highlight>
                  </Typography>
                )}
                <Typography>{headerFn?.(item)}</Typography>
              </Box>
            }
            action={
              <Box>
                created_at{' '}
                <strong>{format(new Date(item.created_at), 'do MMM, yyyy HH:mm')}</strong>
              </Box>
            }
          />
          <CardContent sx={{ '&:last-child': { pb: 2 } }}>
            {promotedKeys.length > 0 && (
              <>
                <ReactJsonView
                  name={'fast-access'}
                  collapsed={1}
                  src={pick(item, promotedKeys)}
                  {...commonJsonViewProps}
                />
                <Divider sx={{ my: 1.5 }} />
              </>
            )}
            <ReactJsonView
              name={'full-json'}
              {...commonJsonViewProps}
              collapsed={0}
              src={attemptParseJson(item)}
            />
          </CardContent>
        </Card>
      </Box>
    );
  };

  const sortedData = Array.isArray(data) ? [...data].sort((a, b) => a.id - b.id) : [data];

  return (
    <Box width={'100%'} display='flex' flexDirection='column' gap={2}>
      {sortedData.map(item => (
        <Fragment key={item.id}>{renderCard(item)}</Fragment>
      ))}
    </Box>
  );
};
