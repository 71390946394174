import type { IFormSettings, TFormType } from '@entities/form';

export const FORM_SETTINGS_KEYS: (keyof IFormSettings)[] = [
  'has_cancel',
  'has_order',
  'has_timer',
  'has_loader',
  'has_payment_processing_timer',
  'payment_processing_timer',
  'has_collapse_wallet_details',
  'has_wallet_holder_name',
  'has_wallet_bank_code',
  'has_qrcode',
  'has_qr_download',
  'has_method_logo',
  'has_deeplink',
  'has_instruction',
  'has_wallet_address',
  'form_type',
];

const DEFAULT_FORM_TYPE = 'basic';

export const assmebleFormSettings = (fd: FormData) => {
  return FORM_SETTINGS_KEYS.reduce((acc, key) => {
    switch (key) {
      case 'form_type':
        acc[key] = (fd.get(key) as TFormType) ?? DEFAULT_FORM_TYPE;
        break;
      case 'payment_processing_timer':
        acc[key] = Number(fd.get(key)) || 0;
        break;
      default:
        acc[key] = fd.get(key) === 'true';
    }
    return acc;
  }, {} as IFormSettings);
};
