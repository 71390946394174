import { RootState } from '@app/store';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ITestModeState {
  enabled: boolean;
}

const initialState: ITestModeState = {
  enabled: false,
};

const testModeSlice = createSlice({
  name: 'testMode',
  initialState,
  reducers: {
    toggleTestMode: state => {
      state.enabled = !state.enabled;
    },
    setTestMode: (state, action: PayloadAction<boolean>) => {
      state.enabled = action.payload;
    },
  },
});

export const actions = testModeSlice.actions;
export const { toggleTestMode, setTestMode } = testModeSlice.actions;
export const selectTestMode = (state: RootState) => state.testMode.enabled;
export default testModeSlice.reducer;
