import { FC, useState } from 'react';

import Select, { MultiValue } from 'react-select';

import { reactFormSelectMultiStyles } from '../react-select-styles';

export interface ISelectMultiOption {
  value: string;
  label: string;
}

export type ISelectMultiOptions = ISelectMultiOption[] | MultiValue<ISelectMultiOption>;

interface IProps {
  name: string;
  options: ISelectMultiOptions;
  value?: MultiValue<ISelectMultiOption>;
  isDisabled?: boolean;
  onChange?: (value: MultiValue<ISelectMultiOption>) => void;
}

export const SelectMulti: FC<IProps> = ({
  name,
  options,
  value = [],
  isDisabled = false,
  onChange,
}) => {
  const [selectedOptions, setSelectedOptions] = useState<MultiValue<ISelectMultiOption>>(value);

  const handleChange = (newValue: MultiValue<ISelectMultiOption>) => {
    setSelectedOptions(newValue);
    onChange?.(newValue);
  };

  return (
    <div className='select-multi-container'>
      <Select
        isMulti
        options={options}
        classNamePrefix='select'
        styles={reactFormSelectMultiStyles}
        value={selectedOptions}
        onChange={handleChange}
        isDisabled={isDisabled}
        maxMenuHeight={200}
      />

      {/* Hidden inputs for form submission */}
      {selectedOptions.map(option => (
        <input key={option.value} type='hidden' name={name} value={option.value} />
      ))}
    </div>
  );
};
