import { useEffect, useRef } from 'react';

import { useSelector } from 'react-redux';

import { useFilter } from '../../table-filter';
import { selectTestMode } from '../model/testModeSlice';

type Props = {
  filter: ReturnType<typeof useFilter>;
};

export function useMerchantTestMode({ filter }: Props) {
  const isTestMode = useSelector(selectTestMode);
  const key = 'filter[merchant_is_test]';
  const isFirstRun = useRef(true);

  // Before we make any queries, set the filter to the current test mode
  if (!filter.currentFilter.get(key)) {
    filter.updateFilter({ [key]: isTestMode ? '1' : '0' });
  }

  // Skip the first run, we already set the correct filter
  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }

    filter.updateFilter({ [key]: isTestMode ? '1' : '0' });
  }, [isTestMode]); // eslint-disable-line react-hooks/exhaustive-deps
}
