import { useFilter } from '@features/table-filter';
import { useMerchantTestMode } from '@features/test-mode-toggler';
import { useRefetchCurrentPageData } from '@shared/hooks/useRefetchCurrentPageData';

import { useOrderDepositQuery } from '../api';
import type { IOrderDepositFilter } from '../model/types';

export const useOrderDeposits = () => {
  const filter = useFilter<IOrderDepositFilter>();
  useMerchantTestMode({ filter });
  const {
    data: orders,
    isLoading,
    isError,
    error,
    isFetching,
    refetch,
  } = useOrderDepositQuery(filter.currentFilter.toString());

  // Refetch data when timezone changes
  useRefetchCurrentPageData({ refetch });

  return {
    orders,
    isError,
    error,
    isLoading,
    isFetching,
    refetch,

    filter,
  };
};
