import { useState, useCallback, ReactNode } from 'react';

import { useHotkeys as useHotkeysHook } from 'react-hotkeys-hook';
import { useNavigate } from 'react-router-dom';
import { Flip, toast, ToastOptions } from 'react-toastify';

import { HotkeysMessages } from '@shared/components/Hotkeys';

type NavigationPath = {
  orders: {
    deposits: string;
    withdrawals: string;
  };
  requests: {
    deposits: string;
    withdrawals: string;
  };
  wallets: {
    deposits: string;
    withdrawals: string;
  };
};

type CommandContext = null | 'orders' | 'requests' | 'wallets';

const NAVIGATION_PATHS: NavigationPath = {
  orders: {
    deposits: '/orders/deposit',
    withdrawals: '/orders/withdrawal',
  },
  requests: {
    deposits: '/requests/deposit',
    withdrawals: '/requests/withdrawal',
  },
  wallets: {
    deposits: '/wallets/deposit',
    withdrawals: '/wallets/withdrawal',
  },
};

export const useHotkeys = (): null => {
  const navigate = useNavigate();
  const [commandMode, setCommandMode] = useState<{
    active: boolean;
    context: CommandContext;
  }>({
    active: false,
    context: null,
  });

  const exitCommandMode = useCallback(() => {
    setCommandMode({ active: false, context: null });
  }, []);

  const showToast = useCallback((message: string | ReactNode, options?: ToastOptions) => {
    toast.dark(message, {
      position: 'top-right',
      autoClose: 13000,
      closeOnClick: true,
      closeButton: false,
      draggable: true,
      pauseOnHover: true,
      hideProgressBar: true,
      transition: Flip,
      icon: false,
    });
  }, []);

  // Exit command mode with Escape (highest priority)
  useHotkeysHook(
    'escape',
    e => {
      toast.dismiss();
      if (!commandMode.active) return;
      e.preventDefault();
      exitCommandMode();
      showToast('Command mode disabled');
    },
    { enabled: true },
  );

  // Handle section selection (second priority)
  useHotkeysHook(
    'o,r,w',
    e => {
      if (!commandMode.active || commandMode.context) return;
      e.preventDefault();

      let context: CommandContext = null;
      switch (e.key.toLowerCase()) {
        case 'o':
          context = 'orders';
          break;
        case 'r':
          context = 'requests';
          break;
        case 'w':
          context = 'wallets';
          break;
      }

      if (context) {
        toast.dismiss();
        setCommandMode(prev => ({ ...prev, context }));
        showToast(HotkeysMessages.selectType(context));
      }
    },
    { enabled: commandMode.active && !commandMode.context },
  );

  // Handle action selection (third priority)
  useHotkeysHook(
    'd,w',
    e => {
      if (!commandMode.active || !commandMode.context) return;
      e.preventDefault();

      const isDeposit = e.key.toLowerCase() === 'd';
      const action = isDeposit ? 'deposits' : 'withdrawals';

      switch (commandMode.context) {
        case 'orders':
          navigate(NAVIGATION_PATHS.orders[action]);
          break;
        case 'requests':
          navigate(NAVIGATION_PATHS.requests[action]);
          break;
        case 'wallets':
          navigate(NAVIGATION_PATHS.wallets[action]);
          break;
      }
      toast.dismiss();
      exitCommandMode();
    },
    { enabled: commandMode.active && commandMode.context !== null },
  );

  // Command mode activation (lowest priority)
  useHotkeysHook(
    'alt+k,meta+k',
    e => {
      if (commandMode.active) return;
      e.preventDefault();
      toast.dismiss();
      setCommandMode({ active: true, context: null });
      showToast(HotkeysMessages.commandMode);
    },
    { enabled: true },
  );

  return null;
};
