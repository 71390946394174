import { ReactNode } from 'react';

import { withRoleAccess } from '@app/routes';
import {
  useCreateWalletSettingsMutation,
  useUpdateWalletMutation,
  walletParserModes,
  walletStatuses,
} from '@entities/wallets';
import type { IWallet, IWalletTableColumns, TWalletType } from '@entities/wallets';
import { ModalEditEntity } from '@features/modal-entity-edit';
import { ModalViewEntity } from '@features/modal-entity-view';
import ModalOpenBtn from '@features/modal-open-btn';
import { RefetchAuto } from '@features/refetch-auto';
import { ITableActionsConfig, TableActionBtns } from '@features/table-action-btns';
import { TableControlMenu } from '@features/table-control-menu';
import { FilterInput, FilterSelect, FilterControlBlock } from '@features/table-filter';
import { WalletCredentials } from '@features/wallets';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { MenuItem } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import { TBody } from '@shared/components/Table';
import { getTypedObjectKeys } from '@shared/types/typedObjectEntries';

import { NewWallet } from '../components/NewWallet';
import { NewWalletMID } from '../components/NewWalletMID';
import { ParserModeToggler } from '../components/ParserModeToggler';
import { StatusToggler } from '../components/StatusToggler';
import { assmebleWalletSettings } from '../utils/assembleWalletSettings';

interface IProps {
  type: TWalletType;
  refetch?: () => void;
  setRefreshInterval?: (interval: string) => void;
  refreshInterval?: string;
}

export const useTableSettings = ({
  type,
  refetch,
  refreshInterval,
  setRefreshInterval,
}: IProps) => {
  // THead settings
  const columnTitles: IWalletTableColumns = {
    name: 'Name',
    status_toggle: 'Status toggle',
    status: 'Status',
    parser_toggle: 'Parser toggle',
    parser_mode: 'Parser mode',
    mid: 'MID',
    actions: 'Actions',
  };

  const renderFilterElement = (key: keyof IWalletTableColumns) => {
    switch (key) {
      case 'status_toggle':
      case 'parser_toggle':
        return null;

      case 'status':
        return <FilterSelect name={key} options={walletStatuses} />;

      case 'parser_mode':
        return <FilterSelect name={key} options={walletParserModes} />;

      case 'actions':
        const NewWalletBtn = withRoleAccess(ModalOpenBtn, ['admin', 'operator']);

        return (
          <FilterControlBlock showVisibilityBtn={false}>
            <TableControlMenu>
              <MenuItem>
                <NewWalletBtn
                  text='New wallet'
                  icon={<AccountBalanceWalletIcon color='success' />}
                  modalContent={<NewWallet type={type} refetch={refetch} />}
                />
              </MenuItem>
              <MenuItem data-menu-type='dropdown'>
                <RefetchAuto
                  refreshInterval={refreshInterval}
                  onChangeInterval={setRefreshInterval}
                />
              </MenuItem>
            </TableControlMenu>
          </FilterControlBlock>
        );

      default:
        return <FilterInput name={key} />;
    }
  };

  // TBody settings

  const renderRow = (wallet: IWallet, defaultRow: ReactNode) => {
    const isParentWallet = wallet.parent_id === null;
    const hasChildWallets = wallet.childs.length > 0;

    const customRow = () => {
      return (
        <tr key={wallet.id} className={`${isParentWallet ? 'parent-wallet' : 'child-wallet'}`}>
          <td colSpan={Object.keys(columnTitles).length}>
            <Accordion className='accordion-block' defaultExpanded>
              <AccordionSummary className='accordion-summary' expandIcon={<ExpandMoreIcon />}>
                <table>
                  <tbody>
                    <tr>
                      {getTypedObjectKeys(columnTitles).map(key => {
                        return (
                          <td key={key}>
                            <div className='table-column__name'>{columnTitles[key]}:</div>

                            {renderItem(key, wallet)}
                          </td>
                        );
                      })}
                    </tr>
                  </tbody>
                </table>
              </AccordionSummary>
              <AccordionDetails className='accordion-details'>
                <table>
                  <TBody items={wallet.childs} renderItem={renderItem} />
                </table>
              </AccordionDetails>
            </Accordion>
          </td>
        </tr>
      );
    };

    return hasChildWallets ? customRow() : defaultRow;
  };

  const renderItem = (key: keyof IWalletTableColumns, wallet: IWallet) => {
    switch (key) {
      case 'name':
        return <span title={wallet.name}>{wallet.name}</span>;

      case 'status_toggle':
        return <StatusToggler wallet={wallet} refetch={refetch} />;

      case 'parser_toggle':
        return <ParserModeToggler wallet={wallet} refetch={refetch} />;

      case 'mid':
        return <span title={wallet.mid.name}>{wallet.mid.name}</span>;

      case 'actions': {
        const excludeKeysEdit: (keyof IWallet)[] = [
          'id',
          'parent_id',
          'merchant_id',
          'currency_id',
          'parser_mode',
          'type',
          'status',
          'wallet_group_id',
          'qr_code_static',
          'reason',

          // Objects
          'bank',
          'mid',
          'childs',
          'settings',
        ];

        const excludeKeysView: (keyof IWallet)[] = [
          'merchant_id',
          'currency_id',
          'wallet_group_id',
          'qr_code_static',
          'reason',
          'settings',
        ];

        const isParentWallet = wallet.parent_id === null;
        !isParentWallet &&
          excludeKeysEdit.push('wallet_address', 'login_credentials_json', 'provider');

        const handleEditWallet = (formData: FormData) => {
          const credentials = formData.get('login_credentials_json');

          if (!credentials) {
            formData.delete('login_credentials_json');
          }

          return formData;
        };

        const handleTransformWalletSettings = (formData: FormData) => {
          const settings = assmebleWalletSettings(formData);
          return {
            id: wallet.id,
            ...settings,
          };
        };

        const settings = wallet.settings;
        const normalizedForm = {
          id: wallet.id,
          ...settings,
        };

        const actionBtnsConfig: ITableActionsConfig = {
          add: {
            isEnabled: isParentWallet,
            isVisible: true,
            popupElem: <NewWalletMID parent_id={wallet.id} refetch={refetch} />,
            popupSize: 'md',
            allowedRoles: ['admin', 'operator'],
          },
          edit: {
            isEnabled: true,
            isVisible: true,
            popupElem: (
              <ModalEditEntity
                title='Edit Wallet'
                entity={wallet}
                excludeKeys={excludeKeysEdit}
                additionalKeys={[{ login_credentials_json: '', provider: wallet.provider.id }]}
                useEditEntityMutation={useUpdateWalletMutation}
                onTransformFormData={handleEditWallet}
                refetch={refetch}
              />
            ),
            popupSize: 'md',
            allowedRoles: ['admin', 'merchant', 'operator'],
          },
          'edit-settings': {
            isEnabled: true,
            isVisible: true,
            popupElem: (
              <ModalEditEntity
                title='Edit Wallet Settings'
                entity={normalizedForm}
                excludeKeys={['id']}
                useEditEntityMutation={useCreateWalletSettingsMutation}
                refetch={refetch}
                onTransformFormData={handleTransformWalletSettings}
              />
            ),
            popupSize: 'md',
          },
          view: {
            isEnabled: true,
            isVisible: true,
            popupElem: (
              <ModalViewEntity
                entity={wallet}
                title='View Monitoring'
                deepEntityValues={{ bank: ['name'], provider: ['name'] }}
                excludeKeys={excludeKeysView}
                additionalData={[
                  {
                    label: 'Credentials:',
                    element: <WalletCredentials walletId={wallet.id} />,
                  },
                ]}
              />
            ),
            popupSize: 'md',
          },
        };

        return <TableActionBtns config={actionBtnsConfig} />;
      }

      default:
        return wallet[key];
    }
  };

  return {
    columnTitles,
    renderFilterElement,
    renderRow,
    renderItem,
  };
};
