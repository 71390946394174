import { FC, useEffect } from 'react';

import startCase from 'lodash/startCase';

import { useCreateWalletSettingsMutation } from '@entities/wallets';
import { FormItemWithError } from '@shared/components/Form';
import Loading from '@shared/components/Loading';
import { useModal } from '@shared/components/Modal';

import { assmebleWalletSettings, WALLET_SETTINGS_KEYS } from '../utils/assembleWalletSettings';

interface IProps {
  parent_id: string;
  parent_name: string;
  refetch: () => void;
}

export const NewWalletSettings: FC<IProps> = ({ parent_id, parent_name, refetch }) => {
  const { closeModal } = useModal();
  const [createWalletSettings, { isLoading, isError, error }] = useCreateWalletSettingsMutation();

  useEffect(() => {
    const submitDefaultSettings = async () => {
      const settings = assmebleWalletSettings(new FormData());
      await createWalletSettings({ id: parent_id, ...settings });
      refetch();
    };
    submitDefaultSettings();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formData = new FormData(e.target as HTMLFormElement);
    const settings = assmebleWalletSettings(formData);
    try {
      const result = await createWalletSettings({ id: parent_id, ...settings });
      if ('data' in result) {
        closeModal();
        refetch();
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      {isLoading && <Loading />}

      <h2>
        New Wallet Settings for <b>{parent_name}</b>
      </h2>
      <form onSubmit={handleSubmit} className='popup-form'>
        {WALLET_SETTINGS_KEYS.map(key => {
          switch (key) {
            case 'min_amount':
              return (
                <FormItemWithError
                  key={key}
                  name={key}
                  label={startCase(key)}
                  error={error}
                  isError={isError}
                >
                  <input type='number' name={key} min={200} max={100000} defaultValue={200} />
                </FormItemWithError>
              );
            case 'max_amount':
              return (
                <FormItemWithError
                  key={key}
                  name={key}
                  label={startCase(key)}
                  error={error}
                  isError={isError}
                >
                  <input type='number' name={key} min={200} max={100000} defaultValue={100000} />
                </FormItemWithError>
              );

            case 'wallet_fee':
              return (
                <FormItemWithError
                  key={key}
                  name={key}
                  label={startCase(key)}
                  error={error}
                  isError={isError}
                >
                  <input type='number' name={key} step={0.1} min={0} defaultValue='0' />
                </FormItemWithError>
              );
            default:
              return (
                <FormItemWithError
                  key={key}
                  name={key}
                  label={startCase(key)}
                  error={error}
                  isError={isError}
                >
                  <input type='number' name={key} step={1} defaultValue='0' />
                </FormItemWithError>
              );
          }
        })}

        <div className='form-submit'>
          <button type='button' className='secondary-btn' onClick={closeModal}>
            Cancel
          </button>
          <button type='submit' disabled={isLoading}>
            Save
          </button>
        </div>
      </form>
    </>
  );
};
