import { useRef } from 'react';

import PercentIcon from '@mui/icons-material/Percent';
import './input-number.css';

interface InputNumberProps {
  name: string;
  variant: 'percentage' | 'default';
  suffix?: string;
  min?: number;
  max: number;
  emptyValue?: string;
  defaultValue?: string;
}

export const InputMonetaryNumber = ({
  name,
  variant,
  suffix = 'INR',
  min = 0,
  max,
  emptyValue,
  defaultValue,
}: InputNumberProps) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const maxNumberLength = (max * 100).toString().length;

  const handleInput = (e: React.FormEvent<HTMLInputElement>) => {
    const input = e.target as HTMLInputElement;
    const value = input.value.replace(/[^\d]/g, '').slice(0, maxNumberLength);

    if (!value) {
      if (emptyValue) input.value = emptyValue;
      return;
    }

    const numValue = parseInt(value) / 100;
    if (numValue > max) {
      input.value = `${max.toFixed(2)}`;
    } else {
      input.value = numValue.toFixed(2);
    }
  };

  return (
    <div className='input-monetary-container'>
      <input
        ref={inputRef}
        className='input-monetary'
        type='number'
        name={name}
        step={0.01}
        min={min}
        max={max}
        defaultValue={defaultValue}
        onInput={handleInput}
      />
      <div className='input-monetary-suffix'>
        {variant === 'percentage' ? (
          <PercentIcon fontSize='small' className='input-monetary-icon' />
        ) : (
          suffix && suffix
        )}
      </div>
    </div>
  );
};
