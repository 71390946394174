import capitalize from 'lodash/capitalize';

import { Kbd } from './Kbd';

export const HotkeysMessages = {
  commandMode: (
    <>
      <div>Command mode active:</div>
      <div>
        <Kbd>O</Kbd> Orders <Kbd>R</Kbd> Requests <Kbd>W</Kbd> Wallets
      </div>
    </>
  ),
  selectType: (type: string) => (
    <>
      <div>{capitalize(type)} go to:</div>
      <div>
        <Kbd>D</Kbd> Deposits <Kbd>W</Kbd> Withdrawals
      </div>
    </>
  ),
};
