import {
  IProvider,
  IProviderTableColumns,
  useCreateProviderSettingsMutation,
  useDeleteProviderMutation,
  useEditProviderMutation,
} from '@entities/provider';
import { ModalDeleteEntity } from '@features/modal-entity-delete';
import { ModalEditEntity } from '@features/modal-entity-edit';
import { ModalViewEntity } from '@features/modal-entity-view';
import { ITableActionsConfig, TableActionBtns } from '@features/table-action-btns';

import { NewProviderModal } from '../components/NewProvider';

interface IProps {
  refetch?: () => void;
}

export const useTableSettings = ({ refetch }: IProps) => {
  const [deleteProvider] = useDeleteProviderMutation();

  // THead settings
  const columnTitles: IProviderTableColumns = {
    name: 'Name',
    email: 'Email',
    company_name: 'Company Name',
    address: 'Address',
    country: 'Country',
    mobile: 'Mobile',
    contact_person: 'Contact Person',
    created_at: 'Created At',
    updated_at: 'Updated At',
    short_name: 'Short Name',
    actions: 'Actions',
  };

  // TBody settings
  const renderRow = (key: keyof IProviderTableColumns, provider: IProvider) => {
    switch (key) {
      case 'actions': {
        const excludeEditKeys: (keyof IProvider)[] = [
          'id',
          'created_at',
          'updated_at',
          'deleted_at',
          'email_verified_at',
          'country',
          'company_name',
          'address',
          'mobile',
          'contact_person',
          'settings',
        ];
        const excludeViewKeys: (keyof IProvider)[] = [
          'deleted_at',
          'country',
          'company_name',
          'address',
          'mobile',
          'contact_person',
          'settings',
        ];

        const settings = provider.settings;
        const normalizedProvider = {
          id: provider.id,
          ...settings,
        };

        const actionBtnsConfig: ITableActionsConfig = {
          edit: {
            isEnabled: true,
            isVisible: true,
            popupElem: (
              <ModalEditEntity
                title='Edit Provider'
                entity={provider}
                excludeKeys={excludeEditKeys}
                useEditEntityMutation={useEditProviderMutation}
                refetch={refetch}
              />
            ),
            popupSize: 'md',
          },
          'edit-settings': {
            isEnabled: true,
            isVisible: true,
            popupElem: (
              <ModalEditEntity
                title='Edit provider settings'
                entity={normalizedProvider}
                excludeKeys={['id']}
                useEditEntityMutation={useCreateProviderSettingsMutation}
                refetch={refetch}
              />
            ),
            popupSize: 'md',
          },
          view: {
            isEnabled: true,
            isVisible: true,
            popupElem: (
              <ModalViewEntity
                excludeKeys={excludeViewKeys}
                entity={provider}
                title='View Provider'
              />
            ),
            popupSize: 'md',
          },
          delete: {
            isEnabled: true,
            isVisible: true,
            popupElem: (
              <ModalDeleteEntity
                id={provider.id}
                message='Do you want to delete this provider?'
                deleteEntity={deleteProvider}
                refetch={refetch}
              />
            ),
            popupSize: 'sm',
          },
        };

        return <TableActionBtns config={actionBtnsConfig} />;
      }

      default:
        return provider[key];
    }
  };

  const NewProvider = () => <NewProviderModal refetch={refetch} />;

  return {
    columnTitles,
    renderRow,
    NewProvider,
  };
};
