import { useEffect } from 'react';

import { Route, Routes, Navigate } from 'react-router-dom';

import { useAuth } from '@entities/auth';
import { useTitle } from '@entities/page';
import AnalyticsMetabasePage from '@pages/analytics-metabase';
import DashboardPage from '@pages/dashboard';
import LoginPage from '@pages/login';
import LogsPage from '@pages/logs';
import OrdersDepositPage from '@pages/orders/deposits';
import OrdersWithdrawalPage from '@pages/orders/withdrawals';
import ProfileSettingsPage from '@pages/profile/settings';
import RequestsDepositPage from '@pages/requests/deposits';
import RequestsWithdrawalPage from '@pages/requests/withdrawals';
import SettingsApiPage from '@pages/settings/api';
import SettingsBanksPage from '@pages/settings/banks';
import SettingsConfigPage from '@pages/settings/config';
import SettingsCurrenciesPage from '@pages/settings/currencies';
import SettingsCurrencyMethodsPage from '@pages/settings/currency-methods';
import SettingsFormsPage from '@pages/settings/forms';
import SettingsIFSCPage from '@pages/settings/ifsc';
import SettingsIPWhitelistPage from '@pages/settings/ip-whitelist';
import SettingsMerchantsPage from '@pages/settings/merchants';
import SettingsMethodsPage from '@pages/settings/methods';
import SettingsMIDsPage from '@pages/settings/mids';
import SettingsProvidersPage from '@pages/settings/providers';
import SettlementsPage from '@pages/settlements';
import TransactionsDepositPage from '@pages/transactions/deposits';
import TransactionsWithdrawalPage from '@pages/transactions/withdrawals';
import WalletsMonitoringPage from '@pages/wallets/monitoring';
import LoadingSpinner from '@shared/components/LoadingSpinner';

import { useRedirectUrl } from './hooks/useRedirectUrl';
import Layout from './layout';
import ForbiddenPage from './routes/components/Forbidden';
import NotFoundPage from './routes/components/NotFound';
import PrivateRoute from './routes/components/PrivateRoute';

import './App.css';

const App = () => {
  const { isAuthenticated, isUserLoading } = useAuth();
  const { redirectUrl } = useRedirectUrl();

  const title = useTitle();

  useEffect(() => {
    document.title = title + ' - Backoffice';
  }, [title]);

  if (isUserLoading) return <LoadingSpinner />;

  const hasRedirectUrl = redirectUrl ? <Navigate to={redirectUrl} /> : null;

  return (
    <Routes>
      <Route path='/' element={isAuthenticated ? hasRedirectUrl : <ForbiddenPage />} />

      {/* NON authenticated routes */}
      <Route path='admin'>
        <Route index element={isAuthenticated ? hasRedirectUrl : <LoginPage role='admin' />} />
      </Route>
      <Route path='merchant'>
        <Route index element={isAuthenticated ? hasRedirectUrl : <LoginPage role='merchant' />} />
      </Route>
      <Route path='provider'>
        <Route index element={isAuthenticated ? hasRedirectUrl : <LoginPage role='provider' />} />
      </Route>

      {/* Authenticated routes */}
      <Route element={<Layout />}>
        <Route path='dashboard' element={<PrivateRoute />}>
          <Route index element={<DashboardPage />} />
        </Route>

        <Route path='analytics' element={<PrivateRoute />}>
          <Route index element={<AnalyticsMetabasePage />} />
        </Route>

        <Route path='settlements' element={<PrivateRoute />}>
          <Route index element={<SettlementsPage />} />
        </Route>

        <Route path='orders' element={<PrivateRoute />}>
          <Route path='deposit' element={<OrdersDepositPage />} />
          <Route path='withdrawal' element={<OrdersWithdrawalPage />} />
        </Route>

        <Route path='requests' element={<PrivateRoute />}>
          <Route path='deposit' element={<RequestsDepositPage />} />
          <Route path='withdrawal' element={<RequestsWithdrawalPage />} />
        </Route>

        <Route path='transactions' element={<PrivateRoute />}>
          <Route path='deposit' element={<TransactionsDepositPage />} />
          <Route path='withdrawal' element={<TransactionsWithdrawalPage />} />
        </Route>

        <Route path='wallets' element={<PrivateRoute />}>
          <Route path='deposit' element={<WalletsMonitoringPage type='deposit' />} />
          <Route path='withdrawal' element={<WalletsMonitoringPage type='withdraw' />} />
          <Route path='settlement' element={<WalletsMonitoringPage type='settlement' />} />
        </Route>

        <Route path='logs' element={<PrivateRoute />}>
          <Route index element={<LogsPage />} />
        </Route>

        <Route path='profile-settings' element={<PrivateRoute />}>
          <Route index element={<ProfileSettingsPage />} />
        </Route>

        <Route path='settings' element={<PrivateRoute />}>
          <Route path='ip_whitelist' element={<SettingsIPWhitelistPage />} />
          <Route path='api' element={<SettingsApiPage />} />
          <Route path='config' element={<SettingsConfigPage />} />
          <Route path='ifsc' element={<SettingsIFSCPage />} />
          <Route path='banks' element={<SettingsBanksPage />} />
          <Route path='methods' element={<SettingsMethodsPage />} />
          <Route path='currencies' element={<SettingsCurrenciesPage />} />
          <Route path='currency-methods' element={<SettingsCurrencyMethodsPage />} />
          <Route path='providers' element={<SettingsProvidersPage />} />
          <Route path='merchants' element={<SettingsMerchantsPage />} />
          <Route path='mids' element={<SettingsMIDsPage />} />
          <Route path='forms' element={<SettingsFormsPage />} />
        </Route>
      </Route>

      <Route path='*' element={<NotFoundPage />} />
    </Routes>
  );
};

export default App;
