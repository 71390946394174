import { baseApi } from '@app/store/index.api';

import {
  IFormResponse,
  INewFormResponse,
  IEditFormQuery,
  IEditFormResponse,
  IDeleteFormQuery,
  IDeleteFormResponse,
  IEditFormSettingsQuery,
  INewFormSettingsResponse,
} from '../model/types';

const formApi = baseApi.injectEndpoints({
  endpoints: builder => ({
    getForms: builder.query<IFormResponse, void>({
      query: () => ({
        url: 'forms',
      }),
    }),
    createForm: builder.mutation<INewFormResponse, FormData>({
      query: query => {
        return {
          url: 'forms',
          method: 'POST',
          body: query,
        };
      },
    }),
    createFormSettings: builder.mutation<INewFormSettingsResponse, IEditFormSettingsQuery>({
      query: query => {
        return {
          url: `forms/${query.id}/settings`,
          method: 'POST',
          body: query,
        };
      },
    }),
    editForm: builder.mutation<IEditFormResponse, IEditFormQuery>({
      query: query => ({
        url: `forms/${query.id}`,
        method: 'PUT',
        body: query,
      }),
    }),
    deleteForm: builder.mutation<IDeleteFormResponse, IDeleteFormQuery>({
      query: query => ({
        url: `forms/${query.id}`,
        method: 'DELETE',
      }),
    }),
  }),
});

export const {
  useGetFormsQuery,
  useCreateFormMutation,
  useEditFormMutation,
  useDeleteFormMutation,
  useCreateFormSettingsMutation,
} = formApi;
