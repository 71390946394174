import {
  IMerchant,
  IMerchantTableColumns,
  INewMerchantQuery,
  useCreateMerchantMutation,
  useDeleteMerchantMutation,
  useEditMerchantMutation,
} from '@entities/merchant';
import { ModalDeleteEntity } from '@features/modal-entity-delete';
import { ModalEditEntity } from '@features/modal-entity-edit';
import { ModalNewEntity } from '@features/modal-entity-new';
import { ModalViewEntity } from '@features/modal-entity-view';
import { ITableActionsConfig, TableActionBtns } from '@features/table-action-btns';

interface IProps {
  refetch?: () => void;
}

export const useTableSettings = ({ refetch }: IProps) => {
  const [deleteMerchant] = useDeleteMerchantMutation();

  // THead settings
  const columnTitles: IMerchantTableColumns = {
    domain: 'Domain',
    created_at: 'Created At',
    updated_at: 'Updated At',
    short_name: 'Short Name',
    actions: 'Actions',
  };

  // TBody settings
  const renderRow = (key: keyof IMerchantTableColumns, merchant: IMerchant) => {
    switch (key) {
      case 'actions': {
        const excludeEditKeys: (keyof IMerchant)[] = [
          'id',
          'created_at',
          'updated_at',
          'deleted_at',
          'wallet_type',
          'wallet_id',
          'bank_id',
          'is_test',
        ];

        const excludeViewKeys: (keyof IMerchant)[] = [
          'deleted_at',
          'wallet_type',
          'wallet_id',
          'bank_id',
        ];

        const actionBtnsConfig: ITableActionsConfig = {
          edit: {
            isEnabled: true,
            isVisible: true,
            popupElem: (
              <ModalEditEntity
                title='Edit Merchant'
                entity={merchant}
                excludeKeys={excludeEditKeys}
                useEditEntityMutation={useEditMerchantMutation}
                refetch={refetch}
              />
            ),
            popupSize: 'md',
          },
          view: {
            isEnabled: true,
            isVisible: true,
            popupElem: (
              <ModalViewEntity
                entity={merchant}
                title='View Merchant'
                excludeKeys={excludeViewKeys}
              />
            ),
            popupSize: 'md',
          },
          delete: {
            isEnabled: true,
            isVisible: true,
            popupElem: (
              <ModalDeleteEntity
                id={merchant.id}
                message='Do you want to delete this merchant?'
                deleteEntity={deleteMerchant}
                refetch={refetch}
              />
            ),
            popupSize: 'sm',
          },
        };

        return <TableActionBtns config={actionBtnsConfig} />;
      }

      default:
        return merchant[key];
    }
  };

  const transformFormData = (data: FormData) => {
    const values = Object.fromEntries(data.entries());
    return Object.assign(values, { is_test: values.is_test === 'on' });
  };

  // New merchant settings
  const newMerchantKeys: (keyof INewMerchantQuery)[] = ['domain', 'short_name', 'is_test'];
  const NewMerchant = () => (
    <ModalNewEntity
      title='New Merchant'
      entityKeys={newMerchantKeys}
      useCreateEntityMutation={useCreateMerchantMutation}
      refetch={refetch}
      onTransformFormData={transformFormData}
    />
  );

  return {
    columnTitles,
    renderRow,
    NewMerchant,
  };
};
