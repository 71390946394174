import { ReactNode } from 'react';

import {
  IMID,
  IMIDTableColumns,
  INewMIDQuery,
  useCreateMIDMutation,
  useDeleteMIDMutation,
  useEditMIDMutation,
} from '@entities/mid';
import { ModalDeleteEntity } from '@features/modal-entity-delete';
import { ModalEditEntity } from '@features/modal-entity-edit';
import { ModalNewEntity } from '@features/modal-entity-new';
import { ModalViewEntity } from '@features/modal-entity-view';
import { ITableActionsConfig, TableActionBtns } from '@features/table-action-btns';

import { EditMIDPricing } from '../components/EditMIDPricing';

interface IProps {
  refetch?: () => void;
}

export const useTableSettings = ({ refetch }: IProps) => {
  const [deleteMID] = useDeleteMIDMutation();

  // THead settings
  const columnTitles: IMIDTableColumns = {
    currency_method_id: 'Currency Method ID',
    'merchant[short_name]': 'Merchant Short Name',
    'currency_method[method][name]': 'Method Name',
    'currency_method[currency][code]': 'Currency Code',
    form_url: 'Form URL',
    created_at: 'Created At',
    traffic: 'Traffic',
    actions: 'Actions',
  };

  // TBody settings
  const renderRow = (key: keyof IMIDTableColumns, mid: IMID): ReactNode => {
    switch (key) {
      case 'merchant[short_name]':
        return mid.merchant?.short_name ?? 'N/A';

      case 'currency_method[method][name]':
        return mid.currency_method.method.name;

      case 'currency_method[currency][code]':
        return mid.currency_method.currency.code;

      case 'actions': {
        const excludeEditKeys: (keyof IMID)[] = [
          'id',
          'created_at',
          'updated_at',
          'deleted_at',
          'currency_method_id',
          'merchant',
        ];

        const excludeViewKeys: (keyof IMID)[] = ['currency_method_id', 'merchant_id', 'deleted_at'];

        const renamedEditLabels = {
          merchant_id: 'Merchant',
          form_id: 'Form',
          currency_method: 'Currency',
        };

        const renamedViewLabels = {
          currency_method_currency_code: 'Currency',
          merchant_short_name: 'Merchant',
        };

        const deepViewEntityValues = {
          currency_method: {
            currency: ['code'],
          },
          merchant: ['short_name'],
        };

        const actionBtnsConfig: ITableActionsConfig = {
          edit: {
            isEnabled: true,
            isVisible: true,
            popupElem: (
              <ModalEditEntity
                title='Edit MID'
                entity={mid}
                excludeKeys={excludeEditKeys}
                useEditEntityMutation={useEditMIDMutation}
                refetch={refetch}
                renamedLabels={renamedEditLabels}
              />
            ),
            popupSize: 'md',
          },
          'view-edit': {
            isEnabled: true,
            isVisible: true,
            popupElem: <EditMIDPricing mid={mid} />,
            popupSize: 'md',
          },
          view: {
            isEnabled: true,
            isVisible: true,
            popupElem: (
              <ModalViewEntity
                deepEntityValues={deepViewEntityValues}
                excludeKeys={excludeViewKeys}
                renamedLabels={renamedViewLabels}
                entity={mid}
                title='View MID'
              />
            ),
            popupSize: 'md',
          },
          delete: {
            isEnabled: true,
            isVisible: true,
            popupElem: (
              <ModalDeleteEntity
                id={mid.id}
                message='Do you want to delete this MID?'
                deleteEntity={deleteMID}
                refetch={refetch}
              />
            ),
            popupSize: 'sm',
          },
        };

        return <TableActionBtns config={actionBtnsConfig} />;
      }

      default:
        return mid[key];
    }
  };

  // New MID settings
  const newMIDKeys: (keyof INewMIDQuery)[] = [
    'form_url',
    'form_id',
    'merchant_id',
    'currency_id',
    'method_id',
    'traffic',
  ];
  const excludeFilterKeys = ['merchantId'];
  const renamedNewLabels = {
    merchant_id: 'Merchant',
    currency_id: 'Currency',
    method_id: 'Method',
    form_id: 'Form',
  };
  const NewMID = () => (
    <ModalNewEntity
      title='New MID'
      entityKeys={newMIDKeys}
      excludeFilterKeys={excludeFilterKeys}
      useCreateEntityMutation={useCreateMIDMutation}
      refetch={refetch}
      renamedLabels={renamedNewLabels}
    />
  );

  return {
    columnTitles,
    renderRow,
    NewMID,
  };
};
