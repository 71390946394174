import { FC } from 'react';

import { withRoleAccess } from '@app/routes';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import ClearIcon from '@mui/icons-material/Clear';
import DoneIcon from '@mui/icons-material/Done';
import EditIcon from '@mui/icons-material/Edit';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import MoneyOffIcon from '@mui/icons-material/MoneyOff';
import SettingsIcon from '@mui/icons-material/Settings';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useModal } from '@shared/components/Modal';
import { useTableContext } from '@shared/components/Table';

import { ITableActionsConfig, IButtonConfig } from '../model/types';

import '../table-action-btns.css';

const Button = (props: React.ButtonHTMLAttributes<HTMLButtonElement>) => {
  return <button {...props}>{props.children}</button>;
};

interface IProps {
  config: ITableActionsConfig;
}

export const TableActionBtns: FC<IProps> = ({ config }) => {
  const { selectedIds, setSelectedIds } = useTableContext();
  const { openModal } = useModal();

  const buttonsConfig: IButtonConfig[] = [
    { key: 'edit', Icon: EditIcon },
    { key: 'edit-settings', Icon: SettingsIcon },
    { key: 'view', Icon: VisibilityIcon },
    { key: 'view-edit', Icon: LocalOfferIcon },
    { key: 'approve', Icon: DoneIcon },
    { key: 'refund', Icon: MoneyOffIcon },
    { key: 'reject', Icon: ClearIcon },
    { key: 'delete', Icon: ClearIcon },
    { key: 'add', Icon: AddCircleIcon },
  ];

  const renderButtons = Object.entries(config).map(([key, action]) => {
    const { popupElem, popupSize, isEnabled, isVisible } = action;
    const Icon = buttonsConfig.find(btn => btn.key === key)?.Icon;

    if (isVisible) {
      const ActionBtn = action.allowedRoles?.length
        ? withRoleAccess(Button, action.allowedRoles)
        : Button;

      return (
        <ActionBtn
          key={key}
          className={`btn-${key} ${isEnabled ? 'is-enabled' : 'is-disabled'}`}
          onClick={() =>
            isEnabled && openModal(popupElem, popupSize, { selectedIds, setSelectedIds })
          }
        >
          <Icon />
        </ActionBtn>
      );
    }

    return null;
  });

  return <div className='action-btns'>{renderButtons}</div>;
};
