import { useFilter } from '@features/table-filter';
import { useMerchantTestMode } from '@features/test-mode-toggler';
import { useRefetchCurrentPageData } from '@shared/hooks/useRefetchCurrentPageData';

import { useOrderWithdrawalQuery } from '../api';
import type { IOrderWithdrawalFilter } from '../model/types';

export const useOrderWithdrawals = () => {
  const filter = useFilter<IOrderWithdrawalFilter>();
  useMerchantTestMode({ filter });

  const {
    data: orders,
    isLoading,
    isError,
    error,
    isFetching,
    refetch,
  } = useOrderWithdrawalQuery(filter.currentFilter.toString());

  // Refetch data when timezone changes
  useRefetchCurrentPageData({ refetch });

  return {
    orders,
    isError,
    error,
    isLoading,
    isFetching,
    refetch,

    filter,
  };
};
