import { FC } from 'react';

import { useCreateFormMutation } from '@entities/form';
import type { INewFormResponse } from '@entities/form';
import { ModalNewEntity } from '@features/modal-entity-new';
import { useModal } from '@shared/components/Modal';

import { NewFormSettings } from './NewFormSettings';

interface IProps {
  refetch: () => void;
}

export const NewFormModal: FC<IProps> = ({ refetch }) => {
  const { openModal } = useModal();

  const handleSuccess = (response: INewFormResponse) => {
    openModal(
      <NewFormSettings
        parent_id={response.data.id}
        parent_name={response.data.name}
        refetch={refetch}
      />,
    );
  };

  return (
    <ModalNewEntity
      title='New Form'
      submitBtnText='Continue'
      entityKeys={['name']}
      useCreateEntityMutation={useCreateFormMutation}
      onSuccess={handleSuccess}
    />
  );
};
