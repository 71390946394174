import { bindActionCreators } from 'redux';

import { authActions } from '@entities/auth';
import { datetimeActions } from '@entities/datetime';
import { pageFeaturesActions } from '@entities/page';
import { userActions } from '@entities/user';
import { walletActions } from '@entities/wallets';
import { loadingActions } from '@features/loading';
import { testModeActions } from '@features/test-mode-toggler';
import { uiLockActions } from '@features/ui-lock';

import { useTypedDispatch } from './useTypedDispatch';

const AllActions = {
  ...authActions,
  ...userActions,
  ...datetimeActions,
  ...pageFeaturesActions,
  ...walletActions,
  ...loadingActions,
  ...testModeActions,
  ...uiLockActions,
};

const useActions = () => {
  const dispatch = useTypedDispatch();

  return bindActionCreators(AllActions, dispatch);
};

export default useActions;
