import { FC } from 'react';

import { useCreateProviderMutation } from '@entities/provider';
import type { INewProviderResponse } from '@entities/provider';
import { ModalNewEntity } from '@features/modal-entity-new';
import { useModal } from '@shared/components/Modal';

import { NewProviderSettings } from './NewProviderSettings';

interface IProps {
  refetch: () => void;
}

export const NewProviderModal: FC<IProps> = ({ refetch }) => {
  const { openModal } = useModal();

  const handleSuccess = (response: INewProviderResponse) => {
    openModal(
      <NewProviderSettings
        parent_id={response.data.id}
        parent_name={response.data.name}
        refetch={refetch}
      />,
    );
  };

  return (
    <ModalNewEntity
      title='New Provider'
      submitBtnText='Continue'
      entityKeys={['name', 'short_name']}
      useCreateEntityMutation={useCreateProviderMutation}
      onSuccess={handleSuccess}
    />
  );
};
