import { FC, useEffect } from 'react';

import useActions from '@app/store/hooks/useActions';
import type { IProvider, IProviderResponse } from '@entities/provider';
import { useProviders } from '@entities/provider';
import { SelectAsync } from '@shared/components/Form';

interface IProps {
  name?: string;
  selectedProvider?: string;
  onChangeProvider?: (providerId: string) => void;
}

export const ProvidersSelectAsync: FC<IProps> = ({ name, selectedProvider, onChangeProvider }) => {
  const { setUiLock } = useActions();

  const { providers, isLoading, isFetching, error, filter } = useProviders({
    name,
    disableUrlFilter: true,
  });

  useEffect(() => {
    setUiLock(isLoading);
  }, [isLoading]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <SelectAsync<IProvider, IProviderResponse>
      name='provider_id'
      data={providers}
      getOptionValue={provider => provider.id}
      getOptionLabel={provider => provider.name}
      filter={filter}
      isLoading={isLoading || isFetching}
      error={error}
      selectedValue={selectedProvider}
      onChange={onChangeProvider}
    />
  );
};
