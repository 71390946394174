import { FormEvent } from 'react';

import { useSelector } from 'react-redux';

import { IMID } from '@entities/mid';
import { WithdrawalFirstStepSelect, DepositFirstStepSelect } from '@features/banks';
import { CurrenciesSelect } from '@features/currencies';
import { FormSettingsFormTypeSelect, FormSettingsSelect, FormsSelect } from '@features/forms';
import { MerchantsSelect } from '@features/merchants';
import { MIDsSelect } from '@features/mids';
import { ProvidersSelectAsync } from '@features/providers';
import { TrafficsSelect } from '@features/traffic';
import { selectUiLock } from '@features/ui-lock';
import { WalletModesSelect, WalletStatusesSelect, WalletTypesSelect } from '@features/wallets';
import { FormItemWithError, InputMonetaryNumber } from '@shared/components/Form';
import { useModal } from '@shared/components/Modal';
import { IBaseObject, TUseMutationPatch } from '@shared/types/fetch-data';
import { getTypedObjectEntries } from '@shared/types/typedObjectEntries';
import { StringOnlyKeys } from '@shared/types/utils';
import { transformObjectKeyToTitle } from '@shared/utils/transformObjectKeyToTitle';

interface IProps<T, TQuery, TResponse> {
  title: string;
  entity: T;
  excludeKeys: (keyof T)[];
  additionalKeys?: Partial<Record<keyof T, string>>[];
  renamedLabels?: Partial<Record<StringOnlyKeys<T>, string>>;
  secondErrorNames?: Partial<Record<keyof T, string>>[];
  useEditEntityMutation: TUseMutationPatch<TQuery, TResponse>;
  onTransformFormData?: (data: FormData) => FormData | Record<string, unknown>;
  refetch: () => void;
  omitUnchangedValues?: boolean;
}

export const ModalEditEntity = <T extends IBaseObject, TQuery, TResponse>({
  title,
  entity,
  excludeKeys,
  additionalKeys = [],
  secondErrorNames = [],
  renamedLabels,
  useEditEntityMutation,
  onTransformFormData,
  omitUnchangedValues = false,
  refetch,
}: IProps<T, TQuery, TResponse>) => {
  const { closeModal } = useModal();
  const { isLocked } = useSelector(selectUiLock);
  const [editEntity, { isLoading, isError, error, isSuccess }] = useEditEntityMutation();

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const formData = new FormData(e.currentTarget);
    if (omitUnchangedValues) {
      for (const [key, value] of formData.entries()) {
        if (key === 'id') continue;
        const originalValue = entity[key as keyof T];
        if (String(originalValue) === String(value)) {
          formData.delete(key);
        }
      }
    }

    const transformedFormData = onTransformFormData ? onTransformFormData(formData) : formData;
    const formValues =
      transformedFormData instanceof FormData
        ? Object.fromEntries(transformedFormData.entries())
        : Object.fromEntries(Object.entries(transformedFormData));

    try {
      const result = await editEntity(formValues as TQuery);

      if ('data' in result) {
        closeModal();
        refetch();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const hasSecondErrorName = (name: keyof T) => {
    return secondErrorNames.some(item => item[name]);
  };

  const getSecondErrorName = (name: keyof T) => {
    return secondErrorNames.find(item => item[name])[name];
  };

  const renderItem = (key: keyof T, value: T[keyof T]) => {
    const keyString = String(key);
    const valueString = value && String(value);

    switch (key) {
      case 'description':
        return <textarea name={keyString} defaultValue={valueString} />;

      case 'email':
        return <input type='email' name={keyString} defaultValue={valueString} />;

      case 'logo':
        return <input type='file' name={keyString} />;

      case 'type':
        return <WalletTypesSelect selectedType={valueString} />;

      case 'status':
        return <WalletStatusesSelect selectedStatus={valueString} />;

      case 'parser_mode':
        return <WalletModesSelect selectedMode={valueString} />;

      case 'currency_method': {
        const value = entity[key] as IMID['currency_method'];
        return <CurrenciesSelect name={keyString} selectedCurrency={value.currency_id} />;
      }

      case 'merchant': {
        const value = entity[key] as IMID['merchant'];
        return <MerchantsSelect name={keyString} selectedMerchant={value.id} />;
      }

      case 'provider_id':
      case 'provider':
        return <ProvidersSelectAsync selectedProvider={valueString} />;

      case 'mid_name':
        return <MIDsSelect selectedMID={valueString} />;

      case 'traffic':
        return <TrafficsSelect selectedTraffic={valueString} />;

      case 'login_credentials_json':
        return <textarea name={keyString} />;

      case 'withdraw_first_step':
        return <WithdrawalFirstStepSelect name={keyString} value={valueString} />;

      case 'deposit_first_step':
        return <DepositFirstStepSelect name={keyString} value={valueString} />;

      case 'form_id':
        return <FormsSelect name={keyString} value={valueString} />;

      // update provider settings
      case 'deposit_fee':
      case 'withdrawal_fee':
      case 'settlement_fee':
      case 'chargeback_fee':
      case 'refund_fee':
        return (
          <InputMonetaryNumber
            defaultValue={valueString}
            name={key}
            max={99.99}
            variant='percentage'
          />
        );
      case 'deposit_transaction_fee':
      case 'withdrawal_transaction_fee':
      case 'settlement_transaction_fee':
      case 'chargeback_transaction_fee':
      case 'refund_transaction_fee':
        return (
          <input
            type='number'
            name={key}
            step={1}
            min={0}
            max={9999.99}
            defaultValue={valueString}
          />
        );
      case 'min_amount':
      case 'max_amount':
        return <input type='number' defaultValue={valueString} min={200} name={key} />;

      // update form settings
      case 'has_cancel':
      case 'has_order':
      case 'has_timer':
      case 'has_wallet_holder_name':
      case 'has_wallet_bank_code':
      case 'has_wallet_address':
      case 'has_qrcode':
      case 'has_qr_download':
      case 'has_instruction':
      case 'has_method_logo':
      case 'has_collapse_wallet_details':
      case 'has_deeplink':
      case 'has_loader':
        return <FormSettingsSelect name={keyString} value={valueString} />;
      case 'form_type':
        return <FormSettingsFormTypeSelect disabled={true} name={keyString} value={valueString} />;
      case 'payment_processing_timer':
        return <input type='number' name={keyString} step={1} min={0} defaultValue={valueString} />;

      default:
        return <input type='text' name={keyString} defaultValue={valueString} />;
    }
  };

  const computedEntity = additionalKeys.reduce((acc, obj) => ({ ...acc, ...obj }), { ...entity });

  return (
    <>
      <h2>{title}</h2>
      <form className='popup-form' onSubmit={handleSubmit} data-testid='edit-entity-form'>
        <input type='hidden' name='id' value={entity.id} />

        {getTypedObjectEntries(computedEntity)
          .filter(([key]) => !excludeKeys.includes(key))
          .map(([key, value]) => {
            const keyString = String(key);
            const label =
              renamedLabels?.[key as StringOnlyKeys<T>] ||
              `${transformObjectKeyToTitle(keyString)}:`;

            const secondErrorNameProps = hasSecondErrorName(key)
              ? { secondName: getSecondErrorName(key) }
              : {};

            return (
              <FormItemWithError
                key={keyString}
                name={keyString}
                {...secondErrorNameProps}
                label={label}
                isError={isError}
                error={error}
              >
                {renderItem(key, value)}
              </FormItemWithError>
            );
          })}
        {isSuccess && <div className='form-success'>Successfully</div>}

        <div className='form-submit'>
          <button type='button' className='secondary-btn' onClick={closeModal}>
            Cancel
          </button>
          <button type='submit' disabled={isLoading || isLocked}>
            Save
          </button>
        </div>
      </form>
    </>
  );
};
