import { usePageTitle } from '@app/hooks/usePageTitle';
import SettingsFormsWidget from '@widgets/settings/forms';

const SettingsFormsPage = () => {
  usePageTitle('Forms');

  return <SettingsFormsWidget />;
};

export default SettingsFormsPage;
