import { Switch } from '@mui/material';

type IProps = {
  name: string;
};

// Note: This component returns "on" or "off" instead of true or false,
// make sure to use onTransformFormData to map to true or false
export const InputSwitch = ({ name }: IProps) => {
  return (
    <Switch
      name={name}
      inputProps={{
        type: 'checkbox',
      }}
    />
  );
};
