import {
  IForm,
  useCreateFormSettingsMutation,
  useDeleteFormMutation,
  useEditFormMutation,
} from '@entities/form';
import { ModalDeleteEntity } from '@features/modal-entity-delete';
import { ModalEditEntity } from '@features/modal-entity-edit';
import { ModalViewEntity } from '@features/modal-entity-view';
import { ITableActionsConfig, TableActionBtns } from '@features/table-action-btns';

import { NewFormModal } from '../components/NewForm';
import { assmebleFormSettings } from '../utils/assembleFormSettings';

interface IProps {
  refetch?: () => void;
}

export const useTableSettings = ({ refetch }: IProps) => {
  const [deleteForm] = useDeleteFormMutation();

  // THead settings
  const columnTitles = {
    name: 'Name',
    actions: 'Actions',
  };

  // TBody settings
  const renderRow = (key: keyof typeof columnTitles, form: IForm) => {
    switch (key) {
      case 'actions': {
        const excludeEditKeys: (keyof IForm)[] = ['id', 'settings'];

        const settings = form.settings;
        const normalizedForm = {
          id: form.id,
          ...settings,
        };

        const handleTransformFormData = (formData: FormData) => {
          const settings = assmebleFormSettings(formData);
          return {
            id: form.id,
            ...settings,
          };
        };

        const actionBtnsConfig: ITableActionsConfig = {
          edit: {
            isEnabled: true,
            isVisible: true,
            popupElem: (
              <ModalEditEntity
                title='Edit Form name'
                entity={form}
                excludeKeys={excludeEditKeys}
                useEditEntityMutation={useEditFormMutation}
                refetch={refetch}
              />
            ),
            popupSize: 'md',
          },
          'edit-settings': {
            isEnabled: true,
            isVisible: true,
            popupElem: (
              <ModalEditEntity
                title='Edit form settings'
                entity={normalizedForm}
                excludeKeys={['id']}
                useEditEntityMutation={useCreateFormSettingsMutation}
                refetch={refetch}
                onTransformFormData={handleTransformFormData}
              />
            ),
            popupSize: 'md',
          },
          view: {
            isEnabled: true,
            isVisible: true,
            popupElem: <ModalViewEntity entity={normalizedForm} title='View Form' />,
            popupSize: 'md',
          },
          delete: {
            isEnabled: true,
            isVisible: true,
            popupElem: (
              <ModalDeleteEntity
                id={form.id}
                message='Do you want to delete this form?'
                deleteEntity={deleteForm}
                refetch={refetch}
              />
            ),
            popupSize: 'md',
          },
        };

        return <TableActionBtns config={actionBtnsConfig} />;
      }

      default:
        return form[key];
    }
  };

  const NewForm = () => <NewFormModal refetch={refetch} />;

  return {
    columnTitles,
    renderRow,
    NewForm,
  };
};
