import { FC } from 'react';

import type { IBank, IBankResponse } from '@entities/bank';
import { useBanks } from '@entities/bank';
import { SelectAsync } from '@shared/components/Form';

interface IProps {
  onChangeBank?: (bankId: string) => void;
}

export const BanksSelectAsync: FC<IProps> = ({ onChangeBank }) => {
  const { banks, isLoading, isFetching, error, filter } = useBanks({
    disableUrlFilter: true,
  });

  return (
    <SelectAsync<IBank, IBankResponse>
      name='bank_id'
      data={banks}
      getOptionValue={bank => bank.id}
      getOptionLabel={bank => bank.name}
      filter={filter}
      isLoading={isLoading || isFetching}
      error={error}
      onChange={onChangeBank}
    />
  );
};
