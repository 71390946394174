import { FC } from 'react';

import get from 'lodash/get';

interface IProps {
  name: string;
  value?: string;
  disabled?: boolean;
  trueValueLabel?: string;
  falseValueLabel?: string;
}

export const formSelectValueLabels: Record<string, { true: string; false: string }> = {
  default: {
    true: 'Show',
    false: 'Hide',
  },
  has_collapse_wallet_details: {
    true: 'On',
    false: 'Off',
  },
};

export const FormSettingsSelect: FC<IProps> = ({ name, value = 'false', disabled = false }) => {
  return (
    <select name={name} defaultValue={value} disabled={disabled}>
      <option value='true'>
        {get(formSelectValueLabels, name, formSelectValueLabels.default).true}
      </option>
      <option value='false'>
        {get(formSelectValueLabels, name, formSelectValueLabels.default).false}
      </option>
    </select>
  );
};

export const FormSettingsFormTypeSelect: FC<IProps> = ({
  name,
  value = 'basic',
  disabled = false,
}) => {
  return (
    <>
      <select name={name} defaultValue={value} disabled={disabled}>
        <option value='basic'>Basic</option>
        <option value='bank_transfer'>Bank Transfer</option>
        <option value='non_utr'>Non UTR</option>
      </select>
      {/* when select is disabled, FormData will exclude the value,
      this ensures that the value is still sent to the backend */}
      {disabled && <input type='hidden' name={name} value={value} />}
    </>
  );
};
