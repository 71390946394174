import { FC, useState } from 'react';

import { v4 as uuidv4 } from 'uuid';

import { useCreateWalletMIDMutation } from '@entities/wallets';
import { MerchantsSelect } from '@features/merchants';
import { MIDsSelect } from '@features/mids';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import { FormItemWithError } from '@shared/components/Form';
import Loading from '@shared/components/Loading';
import { useModal } from '@shared/components/Modal';

import { NewWalletSettings } from './NewWalletSettings';

interface IProps {
  parent_id: string;
  refetch: () => void;
}

interface MerchantMidPair {
  id: string;
  merchantId: string;
  midIds: string[];
}

export const NewWalletMID: FC<IProps> = ({ parent_id, refetch }) => {
  const [merchantMidPairs, setMerchantMidPairs] = useState<MerchantMidPair[]>([
    { id: uuidv4(), merchantId: '', midIds: [] },
  ]);
  const { closeModal, openModal } = useModal();
  const [createWalletMID, { isLoading, isError, error, isSuccess }] = useCreateWalletMIDMutation();

  const handleAddPair = () => {
    setMerchantMidPairs([...merchantMidPairs, { id: uuidv4(), merchantId: '', midIds: [] }]);
  };

  const handleRemovePair = (id: string) => {
    setMerchantMidPairs(prevPairs => prevPairs.filter(pair => pair.id !== id));
  };

  const handleMerchantChange = (id: string, merchantId: string) => {
    const updatedPairs = merchantMidPairs.map(pair =>
      pair.id === id ? { ...pair, merchantId } : pair,
    );
    setMerchantMidPairs(updatedPairs);
  };

  const handleMidChange = (id: string, midIds: string[]) => {
    const updatedPairs = merchantMidPairs.map(pair =>
      pair.id === id ? { ...pair, midIds } : pair,
    );
    setMerchantMidPairs(updatedPairs);
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const allMidIds = merchantMidPairs.flatMap(pair => pair.midIds);

    try {
      const result = await createWalletMID({ parent_id, mid_ids: allMidIds });

      if ('data' in result) {
        closeModal();
        openModal(
          <NewWalletSettings parent_name='Wallet' parent_id={parent_id} refetch={refetch} />,
        );
        refetch();
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      {isLoading && <Loading />}

      <h2>New Wallet MIDs</h2>
      <form className='create-wallet-mid-form' onSubmit={handleSubmit}>
        {merchantMidPairs.map(pair => (
          <div className='form-row' key={pair.id}>
            <FormItemWithError name='merchant_id' label='Merchant:' error={error} isError={isError}>
              <MerchantsSelect
                onChangeMerchant={merchantId => handleMerchantChange(pair.id, merchantId)}
              />
            </FormItemWithError>

            <FormItemWithError name='mid_ids' label='MID:' error={error} isError={isError}>
              <MIDsSelect
                merchantIds={pair.merchantId ? [pair.merchantId] : []}
                onChangeMID={midId => handleMidChange(pair.id, [midId])}
              />
            </FormItemWithError>

            {merchantMidPairs.length > 1 && (
              <IconButton onClick={() => handleRemovePair(pair.id)} color='error'>
                <DeleteIcon />
              </IconButton>
            )}
          </div>
        ))}

        <div className='form-item add-wallet-mid-pair'>
          <IconButton onClick={handleAddPair} color='primary'>
            <AddIcon />
          </IconButton>
        </div>

        {isSuccess && <div className='form-success'>Successfully</div>}

        <div className='form-submit'>
          <button type='button' className='secondary-btn' onClick={closeModal}>
            Cancel
          </button>
          <button type='submit' disabled={isLoading}>
            Create
          </button>
        </div>
      </form>
    </>
  );
};
