import { useState, useEffect } from 'react';

import useActions from '@app/store/hooks/useActions';
import { useGetUserQuery } from '@entities/user';

import { useToken } from '../slice';

export const useAuth = () => {
  const { setCurrentUser } = useActions();
  const token = useToken();
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(!!token);

  const { data: user, isLoading } = useGetUserQuery(undefined, {
    skip: !token,
  });

  useEffect(() => {
    if (user) {
      setCurrentUser(user);
    }
    setIsAuthenticated(!!token);
  }, [user, token]); // eslint-disable-line

  return { isAuthenticated, isUserLoading: isLoading };
};
