import { FC } from 'react';

import { useHotkeys } from 'react-hotkeys-hook';

import RefreshIcon from '@mui/icons-material/Refresh';

interface IProps {
  refetch: () => void;
  withText?: boolean;
}

const RefreshBtn: FC<IProps> = ({ refetch, withText = true }) => {
  useHotkeys('shift+r', () => {
    refetch();
  });

  return (
    <button className='table-control-menu-icon-button' onClick={refetch}>
      <RefreshIcon />
      {withText && 'Refresh'}
    </button>
  );
};

export default RefreshBtn;
