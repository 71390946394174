import {
  IMethod,
  IMethodTableColumns,
  INewMethodQuery,
  useCreateMethodMutation,
  useEditMethodMutation,
} from '@entities/method';
import { ModalEditEntity } from '@features/modal-entity-edit';
import { ModalNewEntity } from '@features/modal-entity-new';
import { ModalViewEntity } from '@features/modal-entity-view';
import { ITableActionsConfig, TableActionBtns } from '@features/table-action-btns';
import RequestStatusLabel from '@shared/components/RequestStatusLabel';

interface IProps {
  refetch?: () => void;
}

export const useTableSettings = ({ refetch }: IProps) => {
  // THead settings
  const columnTitles: IMethodTableColumns = {
    name: 'Name',
    logo_url: 'Logo',
    created_at: 'Created at',
    updated_at: 'Updated at',
    status: 'Status',
    actions: 'Actions',
  };

  // TBody settings
  const renderRow = (key: keyof IMethodTableColumns, method: IMethod) => {
    switch (key) {
      case 'logo_url':
        return (
          <div className='table-cell-logo'>
            <img src={method[key]} alt={method.name} />
          </div>
        );
      case 'status':
        return <RequestStatusLabel status='active' />;

      case 'actions': {
        const excludeEditKeys: (keyof IMethod)[] = [
          'id',
          'created_at',
          'updated_at',
          'deleted_at',
          'logo_url',
          'logo',
        ];

        const excludeViewKeys: (keyof IMethod)[] = ['deleted_at'];

        const actionBtnsConfig: ITableActionsConfig = {
          edit: {
            isEnabled: true,
            isVisible: true,
            popupElem: (
              <ModalEditEntity
                title='Edit Method'
                entity={method}
                excludeKeys={excludeEditKeys}
                useEditEntityMutation={useEditMethodMutation}
                refetch={refetch}
                omitUnchangedValues={true}
              />
            ),
            popupSize: 'sm',
          },
          view: {
            isEnabled: true,
            isVisible: true,
            popupElem: (
              <ModalViewEntity excludeKeys={excludeViewKeys} entity={method} title='View Method' />
            ),
            popupSize: 'md',
          },
        };

        return <TableActionBtns config={actionBtnsConfig} />;
      }

      default:
        return method[key];
    }
  };

  // New merchant settings
  const newMethodKeys: (keyof INewMethodQuery)[] = [
    'currencies',
    'name',
    'logo',
    'payment_link_placeholder',
    'description',
  ];

  const onTransformFormData = (formData: FormData) => {
    const currencies: string[] = [];
    for (const [key, value] of formData.entries()) {
      if (key === 'currencies') {
        currencies.push(value as string);
      }
    }

    const newFormData = new FormData();
    for (const [key, value] of formData.entries()) {
      if (key !== 'currencies') {
        newFormData.append(key, value);
      }
    }

    currencies.forEach(currencyId => {
      newFormData.append('currencies[]', currencyId);
    });

    return newFormData;
  };

  const NewMethod = () => (
    <ModalNewEntity
      title='New Method'
      entityKeys={newMethodKeys}
      useCreateEntityMutation={useCreateMethodMutation}
      onTransformFormData={onTransformFormData}
      refetch={refetch}
    />
  );

  return {
    columnTitles,
    renderRow,
    NewMethod,
  };
};
