import { SerializedError } from '@reduxjs/toolkit';
import {
  FetchBaseQueryError,
  BaseQueryFn,
  MutationDefinition,
  FetchArgs,
  QueryDefinition,
} from '@reduxjs/toolkit/dist/query';
import {
  LazyQueryTrigger,
  MutationTrigger,
  UseMutation,
  UseQuery,
} from '@reduxjs/toolkit/dist/query/react/buildHooks';

export type TObject = Record<string, any>; // eslint-disable-line @typescript-eslint/no-explicit-any

export interface IObjectDeep {
  [key: string]: string | number | boolean | null | IObjectDeep | IObjectDeep[];
}

export interface IBaseObject {
  id: string;
}

export interface IBaseResponse<T> {
  data: T;
}

export interface IDataResponse<T> {
  data: T[];
  meta: IMeta;
  links: ILink;
}

export interface IMeta {
  current_page: number;
  from: number;
  last_page: number;
  links: IMetaLink[];
  path: string;
  per_page: number;
  to: number;
  total: number;
}

interface IMetaLink {
  url: string | null;
  label: string;
  active: boolean;
}

interface ILink {
  first: string;
  last: string;
  prev: string | null;
  next: string | null;
}

export interface IBaseRequestQuery {
  page?: string;
  size?: string;
}

type ApiError = {
  message: string;
  errors?: Record<string, string | string[]>;
};

export const isApiError = (error: unknown): error is { data: ApiError } => {
  const e = error as { data: ApiError };

  return e.data.message !== undefined || e.data.errors !== undefined;
};

// RTK Query types
export type TFetchQueryError = FetchBaseQueryError | SerializedError | undefined;

type TBaseQuery = BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError>;

export type TUseQuery<TQuery, TResponse> = UseQuery<
  QueryDefinition<TQuery, TBaseQuery, never, TResponse, 'baseApi'>
>;

export type TUseLazyQuery<TQuery, TResponse extends IBaseObject> = LazyQueryTrigger<
  QueryDefinition<TQuery, TBaseQuery, never, IDataResponse<TResponse>, 'baseApi'>
>;

export type TUseMutationPost<TResponse> = UseMutation<
  MutationDefinition<FormData, TBaseQuery, never, TResponse, 'baseApi'>
>;
export type TUseMutationPatch<TQuery, TResponse> = UseMutation<
  MutationDefinition<TQuery, TBaseQuery, never, TResponse, 'baseApi'>
>;
export type TUseMutationTrigger<TQuery, TResponse> = MutationTrigger<
  MutationDefinition<TQuery, TBaseQuery, never, TResponse, 'baseApi'>
>;

export type TUseMutation<TQuery, TResponse> = UseMutation<
  MutationDefinition<TQuery, TBaseQuery, never, TResponse, 'baseApi'>
>;
