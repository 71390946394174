import { useFilter } from '@features/table-filter';

import { useGetFormsQuery } from '../api';
import { IFormFilter } from '../model/types';

export const useForms = () => {
  const filter = useFilter<IFormFilter>();

  const {
    data: forms,
    isLoading,
    isError,
    error,
    isFetching,
    isSuccess,
    refetch,
  } = useGetFormsQuery();

  return {
    forms,
    isError,
    error,
    isLoading,
    isFetching,
    isSuccess,
    refetch,

    filter,
  };
};
