import { FC } from 'react';

interface IProps {
  name: string;
  value?: string;
}

export const WithdrawalFirstStepSelect: FC<IProps> = ({ name, value }) => {
  return (
    <select name={name} defaultValue={value}>
      <option value='balance'>Balance</option>
      <option value='withdraw'>Withdraw</option>
    </select>
  );
};
