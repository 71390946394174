import { FC } from 'react';

interface IProps {
  name: string;
  value?: string;
}

export const DepositFirstStepSelect: FC<IProps> = ({ name, value }) => {
  return (
    <select name={name} defaultValue={value}>
      <option value='start'>Start</option>
      <option value='process'>Process</option>
    </select>
  );
};
