import { useForms } from '@entities/form';
import type { IFormTableColumns } from '@entities/form';
import ModalOpenBtn from '@features/modal-open-btn';
import ErrorMessage from '@shared/components/ErrorMessage';
import Loading from '@shared/components/Loading';
import LoadingSpinner from '@shared/components/LoadingSpinner';
import {
  Table,
  THead,
  TBody,
  TableActions,
  TableActionsItem,
  TableContainer,
  TableProvider,
} from '@shared/components/Table';

import { useTableSettings } from './settings/useTableSettings';

const SettingsFormsWidget = () => {
  const { forms, isLoading, isError, error, isFetching, refetch, filter } = useForms();

  const { columnTitles, renderRow, NewForm } = useTableSettings({
    refetch,
  });

  if (isLoading) return <LoadingSpinner />;
  if (isError) return <ErrorMessage error={error} />;
  if (forms) {
    return (
      <>
        {isFetching && <Loading />}

        <TableProvider<IFormTableColumns> columnTitles={columnTitles} {...filter}>
          <TableActions>
            <TableActionsItem>
              <ModalOpenBtn text='New' modalContent={<NewForm />} />
            </TableActionsItem>
          </TableActions>

          <TableContainer>
            <Table>
              <THead />
              <TBody items={forms.data} renderItem={renderRow} />
            </Table>
          </TableContainer>
        </TableProvider>
      </>
    );
  }

  return null;
};

export default SettingsFormsWidget;
