import { FC } from 'react';

import { INewBankQuery, useCreateBankMutation } from '@entities/bank';
import type { INewBankResponse } from '@entities/bank';
import { ModalNewEntity } from '@features/modal-entity-new';
import { useModal } from '@shared/components/Modal';

import { NewBankSettings } from './NewBankSettings';

interface IProps {
  refetch: () => void;
}

const newBankKeys: (keyof INewBankQuery)[] = [
  'name',
  'transaction_validator_name',
  'holder_name',
  'withdraw_first_step',
  'deposit_first_step',
];

export const NewBankModal: FC<IProps> = ({ refetch }) => {
  const { openModal } = useModal();

  const handleSuccess = (response: INewBankResponse) => {
    openModal(
      <NewBankSettings
        parent_id={response.data.id}
        parent_name={response.data.name}
        refetch={refetch}
      />,
    );
  };

  const renamedLabels = {
    transaction_validator_name: 'Bank Validator Name',
  };

  return (
    <ModalNewEntity
      title='New Bank'
      submitBtnText='Continue'
      entityKeys={newBankKeys}
      renamedLabels={renamedLabels}
      useCreateEntityMutation={useCreateBankMutation}
      onSuccess={handleSuccess}
    />
  );
};
