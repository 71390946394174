import { StylesConfig, ControlProps, CSSObjectWithLabel } from 'react-select';

import { CSSObject } from '@emotion/react';

import { ISelectMultiOption } from './components/SelectMulti';

// Reusable styles for both multi and non-multi select
const commonStyles = {
  menu: (provided: CSSObject) =>
    ({
      ...provided,
      zIndex: 5,
    }) as CSSObject,
  menuPortal: (provided: CSSObject) =>
    ({
      ...provided,
      zIndex: 10,
    }) as CSSObject,
  valueContainer: (provided: CSSObject) =>
    ({
      ...provided,
      height: '44px',
      padding: '0 6px',
      zIndex: 5,
    }) as CSSObject,
  input: (provided: CSSObject) =>
    ({
      ...provided,
      margin: '0px',
      border: '0px',
    }) as CSSObject,
  indicatorSeparator: () => ({
    display: 'none',
  }),
  indicatorsContainer: (provided: CSSObject) =>
    ({
      ...provided,
      height: '44px',
    }) as CSSObject,
};

export const reactSelectMultiStyles: StylesConfig<ISelectMultiOption, true> = {
  control: (provided: CSSObjectWithLabel, state: ControlProps<ISelectMultiOption, true>) =>
    ({
      ...provided,
      borderColor: state.menuIsOpen ? '#4e97fd' : '#e3e9ef',
      borderRadius: '8px',
      minHeight: '44px',
      borderWidth: '0px',
    }) as CSSObjectWithLabel,
  option: provided =>
    ({
      ...provided,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      gap: '10px',
    }) as CSSObject,
  ...commonStyles,
};

export const reactFormSelectMultiStyles = {
  ...reactSelectMultiStyles,
  valueContainer: (provided: CSSObject) =>
    ({
      ...provided,
      padding: '4px 8px',
      zIndex: 5,
    }) as CSSObject,
  control: (provided: CSSObjectWithLabel, state: ControlProps<ISelectMultiOption, true>) =>
    ({
      ...provided,
      borderColor: state.isFocused ? '#4e97fd' : '#e3e9ef',
      borderRadius: '8px',
      minHeight: '44px',
      border: '1px solid var(--border-color-muted)',
    }) as CSSObjectWithLabel,
};

// Styles for non-multi select accepts Generic type
export const reactSelectStyles = <OptionType, IsMulti extends boolean = false>(): StylesConfig<
  OptionType,
  IsMulti
> => ({
  control: (provided: CSSObjectWithLabel, state: ControlProps<OptionType, IsMulti>) =>
    ({
      ...provided,
      borderColor: state.isFocused ? '#4e97fd' : '#e3e9ef',
      borderRadius: '8px',
      minHeight: '44px',
      borderWidth: '1px',
    }) as CSSObjectWithLabel,
  ...commonStyles,
});
