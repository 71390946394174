import { FC, useEffect } from 'react';

import startCase from 'lodash/startCase';

import { useCreateProviderSettingsMutation } from '@entities/provider/api';
import { FormItemWithError, InputMonetaryNumber } from '@shared/components/Form';
import Loading from '@shared/components/Loading';
import { useModal } from '@shared/components/Modal';

import {
  assembleProviderSettings,
  PROVIDER_SETTINGS_KEYS,
  PROVIDER_SETTINGS_MAX_AMOUNT,
  PROVIDER_SETTINGS_MIN_AMOUNT,
} from '../utils/assembleProviderSettings';

interface IProps {
  parent_id: string;
  parent_name: string;
  refetch: () => void;
}

export const NewProviderSettings: FC<IProps> = ({ parent_id, parent_name, refetch }) => {
  const { closeModal } = useModal();
  const [createProviderSettings, { isLoading, isError, error }] =
    useCreateProviderSettingsMutation();

  // Submit default settings in case user closes modal without submitting
  useEffect(() => {
    const submitDefaultSettings = async () => {
      const formData = new FormData();
      formData.set('min_amount', PROVIDER_SETTINGS_MIN_AMOUNT.toString());
      formData.set('max_amount', PROVIDER_SETTINGS_MAX_AMOUNT.toString());
      const settings = assembleProviderSettings(formData);
      await createProviderSettings({ id: parent_id, ...settings });
      refetch();
    };
    submitDefaultSettings();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formData = new FormData(e.target as HTMLFormElement);
    const settings = assembleProviderSettings(formData);
    try {
      const result = await createProviderSettings({ id: parent_id, ...settings });
      if ('data' in result) {
        closeModal();
        refetch();
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      {isLoading && <Loading />}

      <h2>
        New Provider Settings for <b>{parent_name}</b>
      </h2>
      <form onSubmit={handleSubmit} className='popup-form'>
        {PROVIDER_SETTINGS_KEYS.map(key => {
          switch (key) {
            case 'min_amount':
            case 'max_amount': {
              const defaultValue =
                key === 'min_amount' ? PROVIDER_SETTINGS_MIN_AMOUNT : PROVIDER_SETTINGS_MAX_AMOUNT;
              return (
                <FormItemWithError
                  key={key}
                  name={key}
                  label={startCase(key)}
                  error={error}
                  isError={isError}
                >
                  <input
                    defaultValue={defaultValue}
                    type='number'
                    min={PROVIDER_SETTINGS_MIN_AMOUNT}
                    name={key}
                  />
                </FormItemWithError>
              );
            }
            case 'deposit_fee':
            case 'withdrawal_fee':
            case 'settlement_fee':
            case 'chargeback_fee':
            case 'refund_fee':
              return (
                <FormItemWithError
                  key={key}
                  name={key}
                  label={startCase(key)}
                  error={error}
                  isError={isError}
                >
                  <InputMonetaryNumber
                    defaultValue='0'
                    name={key}
                    max={99.99}
                    variant='percentage'
                  />
                </FormItemWithError>
              );
            case 'deposit_transaction_fee':
            case 'withdrawal_transaction_fee':
            case 'settlement_transaction_fee':
            case 'chargeback_transaction_fee':
            case 'refund_transaction_fee':
              return (
                <FormItemWithError
                  key={key}
                  name={key}
                  label={startCase(key)}
                  error={error}
                  isError={isError}
                >
                  <input type='number' name={key} step={1} min={0} max={9999.99} defaultValue='0' />
                </FormItemWithError>
              );
            default:
              return null;
          }
        })}

        <div className='form-submit'>
          <button type='button' className='secondary-btn' onClick={closeModal}>
            Cancel
          </button>
          <button type='submit' disabled={isLoading}>
            Create
          </button>
        </div>
      </form>
    </>
  );
};
