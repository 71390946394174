import { RootState } from '@app/store';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface IUiLockState {
  isLocked: boolean;
}

const initialState: IUiLockState = {
  isLocked: false,
};

const uiLockSlice = createSlice({
  name: 'uiLock',
  initialState,
  reducers: {
    setUiLock: (state, action: PayloadAction<boolean>) => {
      state.isLocked = action.payload;
    },
    lockUi: state => {
      state.isLocked = true;
    },
    unlockUi: state => {
      state.isLocked = false;
    },
  },
});

export const actions = uiLockSlice.actions;
export const { lockUi, unlockUi, setUiLock } = uiLockSlice.actions;
export const selectUiLock = (state: RootState) => state.uiLock;
export default uiLockSlice.reducer;
