import { FC } from 'react';

import Skeleton from 'react-loading-skeleton';

import { useForms } from '@entities/form';
import ErrorMessage from '@shared/components/ErrorMessage';
import { Select } from '@shared/components/Form';

import 'react-loading-skeleton/dist/skeleton.css';

interface IProps {
  name?: string;
  value?: string;
  onChangeForm?: (formId: string) => void;
}

export const FormsSelect: FC<IProps> = ({ name = 'form_id', value, onChangeForm }) => {
  const { forms, isLoading, error, isFetching } = useForms();

  if (isLoading) return <Skeleton height={42} />;
  if (error) {
    return (
      <>
        <select></select>
        <ErrorMessage error={error} />
      </>
    );
  }

  if (forms) {
    const hasForms = forms.data.length > 0;
    const selectedValueProp = value ? { selectedValue: value } : {};

    return (
      <Select
        name={name}
        options={forms.data}
        getOptionValue={form => form.id}
        getOptionLabel={form => form.name}
        onChange={onChangeForm}
        {...selectedValueProp}
        isDisabled={isFetching || !hasForms}
      >
        <option value=''>Select form</option>
      </Select>
    );
  }

  return null;
};
