import { FC, useState } from 'react';

import { useCreateBankSettingsMutation, TBankSettingName, IBankSetting } from '@entities/bank';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import { FormItemWithError } from '@shared/components/Form';
import Loading from '@shared/components/Loading';
import { useModal } from '@shared/components/Modal';
import './new-bank-settings.css';

interface IProps {
  isEdit?: boolean;
  parent_id: string;
  parent_name: string;
  refetch: () => void;
  initialMethods?: IBankSetting[];
}

const METHOD_OPTIONS: TBankSettingName[] = ['UPI', 'GPAY', 'PHONEPE', 'PAYTM', 'QR'];

export const NewBankSettings: FC<IProps> = ({
  isEdit = false,
  parent_id,
  parent_name,
  refetch,
  initialMethods,
}) => {
  const [methods, setMethods] = useState<IBankSetting[]>(
    initialMethods || [{ name: '' as TBankSettingName, order: 0, has_message: false }],
  );

  const { closeModal } = useModal();
  const [createBankSettings, { isLoading, isError, error }] = useCreateBankSettingsMutation();

  const handleAddMethod = () => {
    setMethods(prev => [
      ...prev,
      { name: '' as TBankSettingName, order: prev.length, has_message: false },
    ]);
  };

  const handleRemoveMethod = (index: number) => {
    setMethods(prev => prev.filter((_, i) => i !== index));
  };

  const handleMethodChange = (
    index: number,
    field: keyof IBankSetting,
    value: TBankSettingName | number | boolean,
  ) => {
    setMethods(prev =>
      prev.map((method, i) => (i === index ? { ...method, [field]: value } : method)),
    );
  };

  const getAvailableMethodOptions = (currentIndex: number) => {
    const selectedMethods = methods
      .filter((_, index) => index !== currentIndex && methods[index].name)
      .map(method => method.name);
    return METHOD_OPTIONS.filter(option => !selectedMethods.includes(option));
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    try {
      const result = await createBankSettings({
        id: parent_id,
        methods,
      });

      if ('data' in result) {
        closeModal();
        refetch();
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      {isLoading && <Loading />}

      <h2>
        {isEdit ? 'Edit' : 'New'} Bank Settings for <b>{parent_name}</b>
      </h2>
      <form onSubmit={handleSubmit} className='bank-settings-form'>
        {methods.map((method, index) => (
          <div key={index} className='form-row'>
            <FormItemWithError name='name' label='Method:' error={error} isError={isError}>
              <select
                value={method.name}
                onChange={e =>
                  handleMethodChange(index, 'name', e.target.value as TBankSettingName)
                }
                required
              >
                <option value=''>Select method</option>
                {getAvailableMethodOptions(index).map(option => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>
            </FormItemWithError>

            <FormItemWithError name='order' label='Order:' error={error} isError={isError}>
              <input
                type='number'
                min={0}
                max={10}
                value={method.order}
                onChange={e => handleMethodChange(index, 'order', parseInt(e.target.value))}
                required
              />
            </FormItemWithError>

            <FormItemWithError
              name='has_message'
              label='Has Message:'
              error={error}
              isError={isError}
            >
              <select
                value={method.has_message.toString()}
                onChange={e => handleMethodChange(index, 'has_message', e.target.value === 'true')}
                required
              >
                <option value='true'>Yes</option>
                <option value='false'>No</option>
              </select>
            </FormItemWithError>
            <IconButton onClick={() => handleRemoveMethod(index)} color='error'>
              <DeleteIcon />
            </IconButton>
          </div>
        ))}

        <div className='add-method'>
          <Button
            disabled={isLoading || methods.length >= METHOD_OPTIONS.length}
            onClick={handleAddMethod}
            color='primary'
          >
            <AddIcon />
            Add Method
          </Button>
        </div>

        <div className='form-submit'>
          <button type='button' className='secondary-btn' onClick={closeModal}>
            Cancel
          </button>
          <button type='submit' disabled={isLoading}>
            Save
          </button>
        </div>
      </form>
    </>
  );
};
