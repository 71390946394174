import { FilterListOff } from '@mui/icons-material';
import { Button, Tooltip } from '@mui/material';
import { useTableContext } from '@shared/components/Table';

export const ResetFilterBtn = <T,>() => {
  const { resetFilter } = useTableContext<T>();

  return (
    <Tooltip title='Reset filter'>
      <Button
        type='button'
        className='reset-filter-btn'
        size='small'
        color='primary'
        onClick={resetFilter}
      >
        <FilterListOff />
      </Button>
    </Tooltip>
  );
};
