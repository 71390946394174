import { useEffect } from 'react';

import { useSelector } from 'react-redux';
import { useLocation, useSearchParams } from 'react-router-dom';

import useActions from '@app/store/hooks/useActions';
import ScienceIcon from '@mui/icons-material/Science';
import ScienceOutlinedIcon from '@mui/icons-material/ScienceOutlined';
import { Tooltip, IconButton } from '@mui/material';

import { selectTestMode } from '../model/testModeSlice';

const SHOW_ON_URLS = {
  '/orders/deposit': true,
  '/orders/withdrawal': true,
  '/requests/deposit': true,
  '/requests/withdrawal': true,
  '/settings/mids': true,
};

export const TestModeToggler = () => {
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const { setTestMode } = useActions();
  const enabled = useSelector(selectTestMode);

  useEffect(() => {
    if (location.pathname in SHOW_ON_URLS) {
      const initialTestMode = searchParams.get('filter[merchant_is_test]');
      if (!enabled && initialTestMode === '1') setTestMode(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleToggle = () => {
    setTestMode(!enabled);
  };

  if (!(location.pathname in SHOW_ON_URLS)) return null;

  return (
    <Tooltip title={enabled ? 'Disable Test Mode' : 'Enable Test Mode'}>
      <IconButton onClick={handleToggle} color={enabled ? 'secondary' : 'default'}>
        {enabled ? <ScienceIcon /> : <ScienceOutlinedIcon />}
      </IconButton>
    </Tooltip>
  );
};
