import { useState } from 'react';

import { Outlet } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { LoadingContainer } from '@features/loading';
import useDevice from '@shared/hooks/useDevice';
import useServerErrorHandler from '@shared/hooks/useServerErrorHandler';

import Header from './Header';
import Sidebar from './Sidebar';
import { useHotkeys } from '../hooks/useHotkeys';

const Layout = () => {
  const [isWebSidebarOpen, setWebIsSidebarOpen] = useState(true);
  const [isMobSidebarOpen, setMobIsSidebarOpen] = useState(false);

  const device = useDevice();
  useServerErrorHandler();

  useHotkeys();

  return (
    <div className='wrapper' data-platform={device}>
      <Sidebar
        isWebSidebarOpen={isWebSidebarOpen}
        setWebIsSidebarOpen={setWebIsSidebarOpen}
        isMobSidebarOpen={isMobSidebarOpen}
        setMobIsSidebarOpen={setMobIsSidebarOpen}
      />

      <div className='main-column'>
        <Header isMobSidebarOpen={isMobSidebarOpen} setMobIsSidebarOpen={setMobIsSidebarOpen} />

        <main>
          <LoadingContainer />
          <Outlet />
        </main>

        <ToastContainer
          position='top-center'
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme='colored'
        />
      </div>
    </div>
  );
};

export default Layout;
