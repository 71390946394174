import useActions from '@app/store/hooks/useActions';
import { useTimeZone } from '@entities/datetime';
import { Select } from '@shared/components/Form';

import { timeZonesMain } from './timeZones';

const formatTimeZone = (zone: string) => {
  const offset = new Date()
    .toLocaleTimeString('en-US', { timeZoneName: 'short', timeZone: zone })
    .split(' ')[2];
  const timeZoneName = Intl.DateTimeFormat('en-US', { timeZoneName: 'long', timeZone: zone })
    .formatToParts(new Date())
    .find(part => part.type === 'timeZoneName').value;
  return `(${offset}) ${timeZoneName} - ${zone}`;
};

export const TimeZoneSelect = () => {
  const timeZone = useTimeZone();
  const { setTimeZone, setIsNeedCurrentPageDataRefetch } = useActions();

  const handleTimeZoneChange = (newTimeZone: string) => {
    setTimeZone({ timeZone: newTimeZone });
    setIsNeedCurrentPageDataRefetch(true);
  };

  const selectedTimeZone = timeZonesMain.includes(timeZone) ? timeZone : 'UTC';

  return (
    <Select
      name='timeZone'
      options={timeZonesMain}
      getOptionValue={zone => zone}
      getOptionLabel={zone => formatTimeZone(zone)}
      selectedValue={selectedTimeZone}
      onChange={handleTimeZoneChange}
    />
  );
};
