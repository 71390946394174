import { FC, useEffect } from 'react';

import startCase from 'lodash/startCase';

import { useCreateFormSettingsMutation } from '@entities/form';
import { FormSettingsSelect, FormSettingsFormTypeSelect } from '@features/forms';
import { FormItemWithError } from '@shared/components/Form';
import Loading from '@shared/components/Loading';
import { useModal } from '@shared/components/Modal';

import { assmebleFormSettings, FORM_SETTINGS_KEYS } from '../utils/assembleFormSettings';

interface IProps {
  parent_id: string;
  parent_name: string;
  refetch: () => void;
}

export const NewFormSettings: FC<IProps> = ({ parent_id, parent_name, refetch }) => {
  const { closeModal } = useModal();
  const [createFormSettings, { isLoading, isError, error }] = useCreateFormSettingsMutation();

  // Submit default settings in case user closes modal without submitting
  useEffect(() => {
    const submitDefaultSettings = async () => {
      const settings = assmebleFormSettings(new FormData());
      await createFormSettings({ id: parent_id, ...settings });
      refetch();
    };
    submitDefaultSettings();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formData = new FormData(e.target as HTMLFormElement);
    const settings = assmebleFormSettings(formData);
    try {
      const result = await createFormSettings({ id: parent_id, ...settings });
      if ('data' in result) {
        closeModal();
        refetch();
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      {isLoading && <Loading />}

      <h2>
        New Form Settings for <b>{parent_name}</b>
      </h2>
      <form onSubmit={handleSubmit} className='popup-form'>
        {FORM_SETTINGS_KEYS.map(key => {
          switch (key) {
            case 'form_type':
              return (
                <FormItemWithError
                  key={key}
                  name={key}
                  label={startCase(key)}
                  error={error}
                  isError={isError}
                >
                  <FormSettingsFormTypeSelect name={key} />
                </FormItemWithError>
              );
            case 'payment_processing_timer':
              return (
                <FormItemWithError
                  key={key}
                  name={key}
                  label={startCase(key)}
                  error={error}
                  isError={isError}
                >
                  <input type='number' name={key} step={1} min={0} defaultValue='0' />
                </FormItemWithError>
              );
            default:
              return (
                <FormItemWithError
                  key={key}
                  name={key}
                  label={startCase(key)}
                  error={error}
                  isError={isError}
                >
                  <FormSettingsSelect trueValueLabel='Show' falseValueLabel='Hide' name={key} />
                </FormItemWithError>
              );
          }
        })}

        <div className='form-submit'>
          <button type='button' className='secondary-btn' onClick={closeModal}>
            Cancel
          </button>
          <button type='submit' disabled={isLoading}>
            Save
          </button>
        </div>
      </form>
    </>
  );
};
