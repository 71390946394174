import { FC } from 'react';

import type { INewWalletQuery, INewWalletResponse, TWalletType } from '@entities/wallets';
import { useCreateWalletMutation } from '@entities/wallets';
import { ModalNewEntity } from '@features/modal-entity-new';
import { useModal } from '@shared/components/Modal';

import { NewWalletMID } from './NewWalletMID';

interface IProps {
  type: TWalletType;
  refetch: () => void;
}

export const NewWallet: FC<IProps> = ({ type, refetch }) => {
  const { openModal } = useModal();

  const newWalletKeys: (keyof INewWalletQuery)[] =
    type === 'settlement'
      ? ['merchant_id', 'mid_id', 'provider_id', 'type']
      : [
          'type',
          'provider_id',
          'bank_id',
          'name',
          'wallet_address',
          'holder_name',
          'login_credentials_json',
          'bank_code',
        ];

  const handleSuccess = (response: INewWalletResponse) => {
    openModal(<NewWalletMID parent_id={response.data.id} refetch={refetch} />);
  };

  const renamedLabels = {
    bank_id: 'Bank',
    provider_id: 'Provider',
    mid_id: 'MID',
    merchant_id: 'Merchant',
  };

  return (
    <ModalNewEntity
      title='New Wallet'
      submitBtnText='Continue'
      entityKeys={newWalletKeys}
      renamedLabels={renamedLabels}
      useCreateEntityMutation={useCreateWalletMutation}
      onSuccess={handleSuccess}
      otherProps={{ type }}
    />
  );
};
